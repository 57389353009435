import React, { useState } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Modal, Header, Body, Footer, FooterItem, Close } from '@zendeskgarden/react-modals';
import { Row, Col } from '@zendeskgarden/react-grid';
import 'bootstrap/dist/css/bootstrap.css'


import '../../constants/Global.css'
import './styles/Popup.css'
const PopUpImg = (props) => {
    const [visible, setVisible] = useState(false);

    return (
        <div className="row" style={{width:"800px"}}>
            <div className="col-12 Center">
                    {props.visible && (
                    <Modal className="popupBox" onClose={props.onClose}>
                        <img src={props.image} style={{borderRadius:'20px !important'}}></img>
                        
                        <Footer>
                            <FooterItem>
                                <Button onClick={props.onClose} isBasic>
                                Cancel
                                </Button>
                            </FooterItem>
                            
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                    )}
            </div>
        </div>
        
    );
};

export default PopUpImg;