import React, {useState, useEffect, useContext} from 'react';
import 'bootstrap/dist/css/bootstrap.css'

import DropDown from '../atoms/DropDown';
import WarrantyButton from '../atoms/WarrantyButton';
import Swal from 'sweetalert2'
import {getAllCategories, getAllSubCategories,getAllFailureReasons, getAllProducts} from '../../api/DewalServices'
import Loading from '../atoms/Loading'
import AppConext from '../../context/context';
//CSS
import '../../constants/Global.css'
import '../styles/toolimage.css'

import globalLabels from '../../constants/GlobalText.json'
// const optionsDropDownCategory = [
//     'Brussel sprouts',
//     'Cauliflower',
//     'Garlic',
//     'Jerusalem artichoke',
//     'Kale',
// ];

//Renders

var productId = ''

export default function ToolPage (props){

    const {categoriesArray,setcategoriesArray,subCategoriesArray,setSubCategoriesArray,failureReasonsArray,setFailureReasons,productssArray,setProductsArray} = useContext(AppConext)
  

    const [openLogin, setOpenLogin] = useState(false);

    const filteredcategory = categoriesArray.result.items.filter((e)=>{
        return `${e.name}`.toLowerCase().includes(props.selectedCategory.toLowerCase())   ;
    })
    

    const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo ha salido mal por favor inténtelo mas tarde',
        })
    }

    const getProducts = async () =>{
        setOpenLogin(!openLogin)
        try{
            const response = getAllProducts();  
            const data = (await response).data;
            setProductsArray(data)
            return(data)
        }catch(error){
            console.log("error get product")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    } 
    const getCategories = async () =>{
        setOpenLogin(!openLogin)
        try{
            const response = getAllCategories();  
            const data = (await response).data;
            setcategoriesArray(data)
            return(data)
        }catch(error){
            console.log("error get data order")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    } 
    const getSubCategories = async () =>{
        setOpenLogin(!openLogin)
        try{
            const response = getAllSubCategories();  
            const data = (await response).data;
            setSubCategoriesArray(data)
            return(data)
        }catch(error){
            console.log("error get subcategory")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    }
    const getFailureReasons = async () =>{
        setOpenLogin(!openLogin)
        try{
            const response = getAllFailureReasons();  
            const data = (await response).data;
            setFailureReasons(data)
            return(data)
        }catch(error){
            console.log("error get subcategory")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    }

    useEffect(async()=>{
        const categoriesService = await getCategories()
        const subCategoriesService = await getSubCategories()
        const failureReasonsService = await getFailureReasons()
        const productService = await getProducts()
        // if (categoriesService){
        //     const listaCategoriesOptions = categoriesArray.result.items.map((arreglo)=>(arreglo.name))
        //     await getSubCategories(filteredcategory.id)
        // }
        
    }, [])
    const selectedCategory = props.selectedCategory
    const selectedSubCategory = props.selectedSubCategory
    const selectedSubFailure = props.selectedSubFailure

     //Cities Filter by States

    var listaSubCategoriesOptions = '';
    var subCateogirCurrentSelected = '';

    var productList = '';


    const listaCategoriesOptions = categoriesArray.result.items.map((arreglo)=>(arreglo.name))
    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const filteredCategories = categoriesArray.result.items.filter((e)=>{
        return removeAccents(`${e.name}`.toLowerCase()) === removeAccents(props.selectedCategory.toLowerCase())   ;
    })
    
    //SubCategories filter
    var filteredSubCategories = ''
    if (filteredCategories != ''){
        var categoryId = filteredCategories[0].id;

        filteredSubCategories = subCategoriesArray.result.items.filter((e)=>{
            return `${e.category}` === categoryId;
        })
        
        listaSubCategoriesOptions = filteredSubCategories.map((arreglo)=>(arreglo.name)) 



        subCateogirCurrentSelected = subCategoriesArray.result.items.filter((e)=>{
            return `${e.name}`.toLowerCase().includes(props.selectedSubCategory.toLowerCase());
        })
        const subCateogirCurrentSelectedID = subCateogirCurrentSelected.filter((e)=>{
            return `${e.category}` === categoryId;
        })


         //Product filter
        if (subCateogirCurrentSelected != ''){
            var subCategoryId = ''
            if(subCateogirCurrentSelectedID[0]){
                subCategoryId = subCateogirCurrentSelectedID[0].id;
            }

            const filteredProducts = productssArray.result.items.filter((e)=>{
                return `${e.subcategory.id}`.toLowerCase().includes(subCategoryId.toLowerCase())   ;
            })
            productList = filteredProducts.map((arreglo)=>(arreglo.description))    
        }else{
            productList = [
                    '',
            ]
        }
            
    }else{
        listaSubCategoriesOptions = [
                '',
        ]
    }   
    const listFailureReasons = failureReasonsArray.result.items.map((arreglo)=>(arreglo.name))


    var firstProduct = '';
    if(productList){
        const filteretFirstproduct = productssArray.result.items.filter((e)=>{
            return `${e.description}`.toLowerCase().includes(productList[0].toLowerCase())   ;
        })
        firstProduct = filteretFirstproduct[0].url;
    }
    
    

    const filteredProductToSend = productssArray.result.items.filter((e)=>{
        return `${e.description}`.toLowerCase().includes(props.selectedProduct.toLowerCase())   ;
    })
    if (filteredProductToSend[0]){
        productId = filteredProductToSend[0].id;
    }
    
    // productId = filteredProductToSend.id
    const renderToolsDropsowns = () =>{
        var image = ''
        var SKUProduct = ''
        if(filteredProductToSend[0]){
            // image = filteredProductToSend[0].url
                if (filteredProductToSend[0].image != null){
                    image = filteredProductToSend[0].image.url.publicPath
                }else{
                    image = filteredProductToSend[0].url
                }
                SKUProduct = filteredProductToSend[0].product_code;
        }
        

        return(
            <div className="Center">
                    <div className="py-5 pad-l6">
                        <DropDown
                            options = {productList}
                            selectedItem={props.selectedProduct}
                            onSelect={props.setSelectedProduct}
                            style = {{justifyContent:'Center !important'}}/>

                        </div>
                        <div className="row">
                            <div className="col2 col-md-4"></div>
                            <div className="col8 col-md-4">
                            <div>
                            <div className="box-product" style = {{justifyContent:'Center !important'}}>
                                <img src={filteredProductToSend[0] ? image : firstProduct} className="imgProduct" style = {{justifyContent:'Center !important'}}></img>
                            </div>
                            <h5 className="h5-stl pt-2"><strong>SKU: {SKUProduct}</strong></h5>
                            <div className="py-4"></div>
                        </div>
                            </div>
                            <div className="col2 col-md-4"></div>
                        </div>
                    <div className="py-1 pad-l5"></div>
                {/* <Carousel/> */}
                <WarrantyButton
                    label = {props.label} 
                    action = {props.action}
                    disabled = {props.selectedProduct == 'Producto'}
                />
                
                
            </div>
        );
    }

    
    

    // const listaCategoriesOptions = categoriesArray.result.items.map((arreglo)=>(arreglo.name))
    // const listaSubCategoriesOptions = subCategoriesArray.result.items.map((arreglo)=>(arreglo.name))



    // const [selectedCategory, setSelectedCategory] = useState('');
    // const [selectedSubCategory, setSelectedSubCategory] = useState(optionsDropDownSubCategory[0]);
    // const [selectedSubFailure, setSelectedSubFailure] = useState(optionsDropDownFailure[0]);
    const corouselConditional = () =>{
        var checkDropDown = false;
        if (selectedCategory != 'Selecciona una categoría') {
            if (selectedSubCategory != 'Selecciona una subcategoría') {
                if (props.selectedFailure != 'Motivo de falla') {
                    checkDropDown = true;
                }
            }
        }else{checkDropDown = false}
        return(checkDropDown)
    }
    var conditionalDropDowns = corouselConditional();
    

    return (
        <div className="my-5">
            <div className="row">
                <div className="col-12 col-md-6">
                    <h5 className="h5-stl Left px-5 pb-5"><strong>{globalLabels.Toolpage.Title1}</strong></h5>
                    <div className="px-5">
                        <div className="pb-3 pad-l5">
                            <DropDown
                            options = {listaCategoriesOptions}
                            selectedItem={props.selectedCategory}
                            onSelect={props.onSelect}/>
                            
                        </div>
                        <div className="py-1 pad-l5">
                            <DropDown
                            options = {listaSubCategoriesOptions}
                            selectedItem={props.selectedSubCategory}
                            onSelect={props.onSelectSubCategory}/>
                            
                        </div>
                        <div className="pt-3 py-5 pad-l5">
                            <DropDown
                            options = {listFailureReasons}
                            selectedItem={props.selectedFailure}
                            onSelect={props.onSelectFailure}
                            />
                        </div>
                    </div>                
                </div>
                <div className="col-12 col-md-6">
                    <h5 className="h5-stl"><strong>{globalLabels.Toolpage.Title2}</strong></h5>

                    {conditionalDropDowns === true && renderToolsDropsowns()}
                </div>
            </div>
            <div className="py-2">
                
            </div>
            <Loading
            open = {openLogin}/>
        </div>
    );
}   

export function ProductId (){
    return productId;
}