import React ,{ useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css'

// COMPONENTS
import HeaderMain from '../components/molecules/HeaderMain'
import HeaderDewalt from '../components/molecules/HeaderDewalt'
import FooterMain from '../components/molecules/FooterMain'
import InputGray from '../components/atoms/InputGray';
import ValidationAlert from '../components/atoms/ValidationAlert'
import {Link} from 'react-router-dom'

//CSS
import '../constants/Global.css'
import './style/mainPage.css'
import '../components/styles/formGray.css'

export default function TrackingPage (){
    const [formTrackingNumber, setFormTrackingNumber] =useState('')

    var ValidationWarningReturnZipcode = false;
    var disabledButton = false;
    if(formTrackingNumber != ''){
        const digits_only = (currentValuePostalCode) => [...currentValuePostalCode].every(c => '0123456789'.includes(c))
        const numberOnlyValidation = digits_only(formTrackingNumber)
        if (!numberOnlyValidation){
            ValidationWarningReturnZipcode = true;
            disabledButton = true;
        }
        else if (formTrackingNumber.toString().length===10){
            ValidationWarningReturnZipcode = false
            disabledButton = false;

        }else{
            ValidationWarningReturnZipcode = true 
            disabledButton = true;
        }
    }
    if(formTrackingNumber == ''){
        disabledButton = true;
    }

    return (
        <div>
            <HeaderMain />
            <div className="Center">
                <HeaderDewalt />
            </div> 

            <div className="Center">
                <div className="py-5"></div>
                <h2 className="h2-stl"><strong>RASTREAR MI ENVÍO</strong></h2>
                <div className="py-5"></div>
                
            </div>
            <div className="row">
                <div className="col-1 col-md-2"></div>
                <div className="col-11 col-md-8">
                    <h5 className="h5-stl Left gray-color"><strong>Ingresa el numero de guía</strong></h5>
                    <div className="py-3"></div>
                    <div style={{display:'flex'}}>
                        <InputGray
                            type        = "number"
                            name        = "GuideDHL"
                            id          = "GuideDHL"
                            placeholder = "Máximo 10 numeros"
                            // style       = {{background: '#EBEBEB',width:'60vw',height:'70px',border:'1px solid #C4C4C4',borderRigthColor:'#EBEBEB'}}
                            currentValue = {formTrackingNumber}
                            handleOnInputValue = {(data) =>{setFormTrackingNumber(data)}}
                        /> 
                        <div className="divbutton-stl-g">
                            <Link to ={`/TrackingUser/${formTrackingNumber}`}>
                                <button disabled = {disabledButton ===true} style={{
                                    height:'30px',
                                    width:'30px',
                                    color:'#000',
                                    backgroundColor:'#FFBE17',
                                    border:'0px',
                                    padding:'1px 1px'}}><strong>{">"}</strong>
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className="pt-5 Center" style={{ display:'flex',justifyContent:'center', textAlign:'center'}}>
                        {ValidationWarningReturnZipcode && <ValidationAlert message="Número invalido"/>}
                    </div>
                    
                </div>
                <div className="py-5"></div>
                <div className="py-5"></div>
                <div className="py-5"></div>
                <div className="py-3"></div>

                <div className="col-12 col-md-2"></div>
            </div>

            <FooterMain/>
        </div>
    );
}   
