import './App.css';
import MainPage from './pages/MainPage'
import WarrantyUserPage from './pages/WarrantyUserPage';
import TrackingPage from './pages/TrackingPage'
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import TrackingPageId from './pages/TrakingPageId'

import AppConext from './context/context';
import useglobalStates from './hooks/globalStates';

function App() {
  const globalState = useglobalStates();
  return (
    <AppConext.Provider value={globalState}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route exact path="/Warranty" component={WarrantyUserPage} />
          <Route exact path="/TrackingUser" component={TrackingPage} />
          <Route exact path="/TrackingUser/:id"  render={(routerProps)=><TrackingPageId numberGuide = {routerProps.match.params.id}/>} />
        </Switch>
      </BrowserRouter>
    </AppConext.Provider>
  );
}

export default App;
