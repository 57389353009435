import React from 'react';
import '../styles/login.css'
import 'bootstrap/dist/css/bootstrap.css'

import LogoDW from '../../images/DEWALT_Logo_Footer.png'
import '../../constants/Global.css'
import '../styles/footer.css'
import '../../pages/style/mainPage.css'
import TextFooter from '../../images/text-footer.png'

function HeaderMain (){
    return(
        <div>
            <div className="background-yellow2 px-5 py-4">
                <img className="imgFooterSolid mx-3" src={TextFooter} alt="SOLIDEZ GARANTIZADA " ></img>
            </div>
            <div className="footer-stl">
            <div className="container container-stl">
                <div className="row">
                    <div className="col-12 col-lg-10 py-4">
                        <h6 className="h6-stl white-color ">Todos los derechos reservados 2021 DEWALT.</h6>
                        <h6 className="h6-stl white-color  "> <a className="Terms" href="https://www.stanleyblackanddecker.com/privacy-policy" target="_blank">Política de Privacidad</a>| <a className="Terms" href="https://mx.dewalt.global/terminos-y-condiciones" target="_blank">Términos y Condiciones</a></h6>
                    </div>
                    <div className="col-12 col-md-2">
                        <img className="logoFooter" src={LogoDW} alt="header Fondo + logo"></img>
                    </div>
                </div>
            </div>
        </div>
        </div>
        
    );
}

export default HeaderMain;