import React, { useRef, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { Item, Menu, Label, Field, Dropdown, Autocomplete } from '@zendeskgarden/react-dropdowns';
import { Row, Col } from '@zendeskgarden/react-grid';

//CSS
import '../../constants/Global.css'
import './styles/DropDownWhite.css'


export default function DropDownWhite (props){
    const selectedItem = props.selectedItem
    const [inputValue, setInputValue] = useState('');
    const [matchingOptions, setMatchingOptions] = useState(props.options);

    const filterMatchingOptionsRef = useRef(
        debounce((value) => {
        const matchedOptions = props.options.filter(
            option => option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        );
        setMatchingOptions(matchedOptions);
        }, 300)
    );

    useEffect(() => {
        filterMatchingOptionsRef.current(inputValue);
    }, [inputValue]);

    let filteredDropdown = ['']
    if (props.options){
        filteredDropdown = props.options.filter((option)=>{
          return option.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1 ;
        })
    }
    return (
        <Row justifyContent="left" >
            <Col sm={5}  >
                <Dropdown
                inputValue={inputValue}
                selectedItem={props.selectedItem}
                onSelect={props.onSelect}
                onInputValueChange={(value) => setInputValue(value)}
                downshiftProps={{ defaultHighlightedIndex: 0 }}
                >
                <Field >
                    <Label hidden >Accessibly hidden label</Label>
                    <Autocomplete 
                    style = {{
                    color:  '#818181',
                    borderRadius:'0',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',
                    width:'378px',
                    height:'40px',
                    border:'1px solid'
                    }}>{selectedItem}</Autocomplete>

                </Field>
                <Menu style = {{backgroundColor: '#FFF',
                    color: '#818181',
                    borderRadius:'0',
                    fontFamily: 'IBM Plex Sans Condensed',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',

                    }}
                >
                    {filteredDropdown.length ? (
                    filteredDropdown.map(option => (
                        <Item key={option} value={option}>
                        <span>{option}</span>
                        </Item>
                    ))
                    ) : (
                    <Item disabled>No matches found</Item>
                    )}
                </Menu>
                </Dropdown>
            </Col>

        </Row>

    );
}   
