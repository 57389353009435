import React, {useState} from 'react';
import '../../constants/Global.css'
import '../../pages/style/mainPage.css'
import 'bootstrap/dist/css/bootstrap.css'
import {Link} from 'react-router-dom'
import PopUp from '../atoms/PopUp'

import WarrantyBox from '../../images/WarrantyBox.png'
import DefaultButton from '../atoms/DefaultButton'
export default function WarrantySection (){
    const [visible, setVisible] = useState(false);
    return(
        <div className="py-5 background-gray">
            <h2 className="h2-stl black-color Center"><strong>¿TU PRODUCTO SE ENCUENTRA BAJO GARANTÍA?</strong></h2>
            <h4 className="h4.stl black-color Center pt-5">Nuestro sistema de garantias incluye 3 años de garantía limitada, 1 año de <br/>mantenimiento gratis y 90 dias de satisfacción garantizada.</h4>
            <div className="pt-5 Center">
                <img className="img-width" src={WarrantyBox} alt="WarrantyBox DEWALT" ></img>
            </div>
            <div className="container py-5">
                <div className="row">
                    <div className="col-0 col-md-1"></div>
                    <div className="col-12 col-md-3 py-3 Center">
                    <Link to ={'/Warranty'} style = {{textDecoration:'none'}}>
                        <DefaultButton
                        label = "SÍ, MI HERRAMIENTA TIENE GARANTÍA" />
                    </Link>
                    </div>
                    <div className="col col-md-3"></div>
                    <div className="col-12 col-md-3 py-3 Center">
                        <DefaultButton
                        label = "NO , MI HERRAMIENTA NO TIENE GARANTÍA " 
                        action = {() => setVisible(true)}/>
                        <PopUp
                        visible = {visible}
                        onClose= {() =>setVisible(false)}/>
                    </div>
                    <div className="col-0 col-md-2"></div>
                </div>
            </div>
            <div>
            <h4 className="h4.stl black-color Center pt-5">Conoce todos los términos y condiciones de nuestra garantía <a className="linkText" href="https://mx.dewalt.global/index.php/informacion-de-garantia" target="_blank">aquí</a></h4>
            </div>
        </div>
    );
}
