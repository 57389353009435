import React, {useState, useEffect, useContext} from 'react';
import 'bootstrap/dist/css/bootstrap.css'

import HeaderMain from '../components/molecules/HeaderMain';
import HeaderDewalt from '../components/molecules/HeaderDewalt'
import FooterMain from '../components/molecules/FooterMain'
import TabMenu from '../components/molecules/TabMenu'
import WarrantyButton from '../components/atoms/WarrantyButton';
import ToolPage from '../components/templates/ToolPage';
import PurchasePage from '../components/templates/Purchase'
import OriginShipment from '../components/templates/OriginShipment';
import ReturnShipment from '../components/templates/ReturnShipment';
import UserData from '../components/templates/UserData';
import AppConext from '../context/context';
import Confirmation from '../components/templates/Confirmation';

//Variables externas
import {ProductId} from '../components/templates/ToolPage'
import {dewaltServiceIdOrigin , dewaltServiceIdReturn,dewaltServiceZipCode, DHLid} from '../components/templates/ReturnShipment'
import {sendDHLIdOrigin, cambiodecajita1, cambiodecajita2} from '../components/templates/OriginShipment'
import {SendfinalData} from '../components/templates/UserData'
//CSS
import '../constants/Global.css'

export default function WarrantyUserPage (){
    const [loading, setLoading] = useState(false)

    const [valuetab, setValuetab] = useState('Tu Herramienta');
    const [checkValue, setCheckValue] = useState('0');
    const [checkValueReturn, setCheckValueReturn] = useState('0');

    //dropdown TOOLSPAGE
    const {selectedCategory, setSelectedCategory,selectedSubCategory, setSelectedSubCategory,selectedFailure, setSelectedFailure, selectedProduct, setSelectedProduct} = useContext(AppConext)


    //States PURCHASE PAGE
    const [stateDate, setStateDate] = useState(new Date());
    const [currentReceipt, setCurrentReceipt]=useState('')
    const [fileName, setFileName] = useState('')

    //States ORIGIN SHIPMENT PAGE
    const {selectedStateOrigin,setSelectedStateOrigin,selectedAdressCityOrigin,selectedAdressCityReturn,selectedStateReturn,setSelectedStateReturn} = useContext(AppConext)
    const [selectedSucursalOrigin, setSelectedSucursalOrigin] = useState('');

    const [selectedAdressState, setSelectedAdressState] = useState('Estado');
    const [formOriginAdress, setFormOriginAdress] =useState('')
    const [formOriginAdress2, setFormOriginAdress2] =useState('')
    const [formOriginPostalCode, setFormOriginPostalCode] =useState('')
    const [formOriginInfo, setFormOriginInfo] =useState('')
    const [checkbox1State, setCheckbox1State] =useState(false)
    const [checkbox2State, setCheckbox2State] =useState(false)
    const [currentCheckBox1Status, setCurrentCheckBox1Status] = useState(false)
    const [currentCheckBox2Status, setCurrentCheckBox2Status] = useState(false)
    //States RETURN SHIPMENT PAGE
    const [selectedSucursalReturn, setSelectedSucursalReturn] = useState('');
    const [selectedReturnAdressState, setSelectedReturnAdressState] = useState('Estado');
    
    const [formReturnAdress, setFormReturnAdress] =useState('')
    const [formReturnAdress2, setFormReturnAdress2] =useState('')
    const [formReturnPostalCode, setFormReturnPostalCode] =useState('')
    const [formReturnInfo, setFormReturnInfo] =useState('')

    //States UserDate
    const [formUserName, setFormUserName] = useState('')
    const [formUserEmail, setFormUserEmail] = useState('')
    const [formUserPostalCode, setFormUserPostalCode] = useState('')
    const [formUserPhone, setFormUserPhone] = useState('')
    const [formUserCompany, setFormUserCompany] = useState('')
    const [checkBoxTerm, setCheckBoxTerm] = useState(false)
    const [checkBoxInfo, setCheckBoxInfo] = useState(false)
    const [counter, setCounter] = useState(0)

    //Send STATES
    const [selectedProductToSend, setSelectedProductToSend] = useState('');
    const productIdToSend = ProductId();
    const dewalServiceIdToSend = dewaltServiceIdOrigin();
    const dewalServiceIdToReturn = dewaltServiceIdReturn();
    const dewalServiceZipToSend = dewaltServiceZipCode();
    const DHLidSelected = DHLid();
    const DLidOrigin = sendDHLIdOrigin();
    const FinalData = SendfinalData();

    //TabMenu

    const [valueOptiontab, setValueOptiontab] = useState(0);
    
    const handleChangeTab = (event, newValue) => {
        setValueOptiontab(newValue);
    };  
 
    // const getBase64 = (file) => {
    //     return new Promise((resolve) => {
    //         let fileInfo;
    //         let baseURL = '';
    //       // Make new FileReader
    //         let reader = new FileReader();
    
    //       // Convert the file to base64 text
    //         reader.readAsDataURL(file);
    
    //       // on reader load somthing...
    //         reader.onload = () => {
    //         // Make a fileInfo Object
    //         baseURL = reader.result;
    //         resolve(baseURL);
    //         };
    //     });
    // };
    // var status = '';
    // var files = '';
    const handleChangeStatus = async(meta, status,files) => {

        if(status==='done'){
            setCurrentReceipt(files[0].file)
            setFileName(files[0].file.name)
        }
    }

    
    
     //Renders
    //TODO MIRAR EL CHECKBOX DEL USERDATE
    const renderToolsPage = () =>{ 
        return(
            <div >
                <ToolPage
                label = 'SIGUIENTE'
                action = {()=>{setValuetab('Tu compra') 
                                setValueOptiontab(1)}}
                selectedCategory = {selectedCategory}
                onSelect = {item => setSelectedCategory(item)}
                selectedSubCategory = {selectedSubCategory}
                onSelectSubCategory = {item => setSelectedSubCategory(item)}
                selectedFailure  = {selectedFailure}
                onSelectFailure  = {item => setSelectedFailure(item)}
                selectedProduct ={selectedProduct}
                setSelectedProduct = {item =>setSelectedProduct(item)}
                />
                
            </div>
        );
    }

    const renderPurchasePage = (props) =>{
        return(
            <div >
                <PurchasePage
                backAction = {()=> {setValuetab('Tu Herramienta')
                                    setValueOptiontab(0)}}
                nextAction = {()=> {setValuetab('Tu envio de origen')
                                    setValueOptiontab(2)}}
                stateDate = {stateDate}
                setStateDate = {setStateDate}
                fileName={fileName}
                handleChangeStatus={handleChangeStatus}
                currentReceipt = {currentReceipt}
                />
            </div>
        );
    }

    const renderOriginShipment = ()=>{
        return(
            <div >
                <OriginShipment
                backAction = {()=> {setValuetab('Tu compra')
                                    setValueOptiontab(1)}}
                nextAction = {()=> {setValuetab('Tu envio de regreso')
                                    setValueOptiontab(3)}}
                actionCheckBox1= {()=>setCheckValue(1)}
                actionCheckBox2= {()=>setCheckValue(2)}
                checkValue = {checkValue}
                selectedStateOrigin = {selectedStateOrigin}
                selectedSucursalOrigin = {selectedSucursalOrigin}
                setSelectedStateOrigin = {item => setSelectedStateOrigin(item)}
                setSelectedSucursalOrigin = {item => setSelectedSucursalOrigin(item)}
                // selectedAdressCity = {selectedAdressCity}
                selectedAdressState = {selectedAdressState}
                // setSelectedAdressCity = {item => {setSelectedAdressCity(item)}}
                setSelectedAdressState = {item => {setSelectedAdressState(item)}}

                currentValueAdress = {formOriginAdress}
                handleOnInputValueAdress = {(data) =>{setFormOriginAdress(data)}}
                currentValueAdress2 = {formOriginAdress2}
                handleOnInputValueAdress2 = {(data) =>{setFormOriginAdress2(data)}}
                currentValuePostalCode = {formOriginPostalCode}
                handleOnInputValuePostalCode = {(data) =>{setFormOriginPostalCode(data)}}
                currentValueInfo = {formOriginInfo}
                handleOnInputValueInfo = {(data) =>{setFormOriginInfo(data)}}
                checkbox1State = {checkbox1State}
                checkbox2State = {checkbox2State}

                />
                {/* <InputFormBig 
                            type        = "text"
                            name        = "Information"
                            id          = "Information"
                            placeholder = "Información adicional"
                            style       = {{heigth:'106px'}}
                            currentValue = {userDate}
                            handleOnInputValue = {(data) =>{setUserDate(data)}}
                            
                        /> */}
            </div>
        );
    }

    const renderReturnShipment = ()=>{
        return(
            <div >
                <ReturnShipment
                backAction = {()=>{setValuetab('Tu envio de origen')
                                setValueOptiontab(2)}}
                nextAction = {()=>{setValuetab('Tus datos')
                                setValueOptiontab(4)}}
                checkValue = {checkValue}
                checkValueReturn = {checkValueReturn}
                actionCheckBox1= {()=>setCheckValueReturn(1)}
                actionCheckBox2= {()=>setCheckValueReturn(2)}
                actionCheckBox3= {()=>setCheckValueReturn(3)}
                actionCheckBox4= {()=>setCheckValueReturn(4)}
                selectedStateReturn = {selectedStateReturn}
                selectedSucursalReturn = {selectedSucursalReturn}
                setSelectedStateReturn = {item => setSelectedStateReturn(item)}
                setSelectedSucursalReturn = {item => setSelectedSucursalReturn(item)}
                // selectedReturnAdressCity = {selectedReturnAdressCity}
                selectedReturnAdressState = {selectedReturnAdressState}
                // setSelectedReturnAdressCity = {item => setSelectedReturnAdressCity(item)}
                setSelectedReturnAdressState = {item => setSelectedReturnAdressState(item)}

                currentValueAdress = {formReturnAdress}
                handleOnInputValueAdress = {(data) =>{setFormReturnAdress(data)}}
                currentValueAdress2 = {formReturnAdress2}
                handleOnInputValueAdress2 = {(data) =>{setFormReturnAdress2(data)}}
                currentValuePostalCode = {formReturnPostalCode}
                handleOnInputValuePostalCode = {(data) =>{setFormReturnPostalCode(data)}}
                currentValueInfo = {formReturnInfo}
                handleOnInputValueInfo = {(data) =>{setFormReturnInfo(data)}}

                selectedStateOrigin = {selectedStateOrigin}
                
                />
            </div>
        );
    }

    function countClicks () {
        if (counter === 0){
            setCheckBoxTerm(true)
            setCounter(1)
        }
        if (counter === 1){
            setCounter(0)
            setCheckBoxTerm(false)
        }
    }

    const renderUserData = ()=>{
        //TODO MIRAR EL CHECKBOX DEL USERDATE
        return( 
            <div >
                <UserData
                backAction = {()=> {setValuetab('Tu envio de regreso')
                            setValueOptiontab(3)}}
                nextAction = {()=>{setValuetab('Confirmación')
                                    setValueOptiontab(5)}}
                actionCheckBox1 = {()=>countClicks()}
                actionCheckBox2 = {()=>setCheckBoxInfo(true)}
                currentValueName = {formUserName}
                handleOnInputValueName = {(data) =>{setFormUserName(data)}}
                currentValueEmail = {formUserEmail}
                handleOnInputValueEmail = {(data) =>{setFormUserEmail(data)}}
                currentValuePostalCode = {formUserPostalCode}
                handleOnInputValuePostalCode ={(data) =>{setFormUserPostalCode(data)}}
                currentValuePhone = {formUserPhone}
                handleOnInputValuePhone = {(data) =>{setFormUserPhone(data)}}
                currentValueCompany = {formUserCompany}
                handleOnInputValueCompany = {(data) =>{setFormUserCompany(data)}}
                checkBoxTerm = {checkBoxTerm}
                checkBoxInfo = {checkBoxInfo}

                productIdToSend = {productIdToSend}
                selectedFailure = {selectedFailure}
                stateDate = {stateDate}
                invoice = {currentReceipt}

                ZipCode = {dewalServiceZipToSend}
                
                origin_adress = {formOriginAdress}
                origin_description = {formOriginAdress2}
                origin_postal_code = {formOriginPostalCode}
                origin_state = {selectedStateOrigin}
                origin_city =  {selectedAdressCityOrigin}
                origin_observations = {formOriginInfo}
                user_name = {formUserName}
                user_lada = {formUserCompany}
                user_phone = {formUserPhone}
                user_email = {formUserEmail}

                checkValue = {checkValue}
                checkValueReturn = {checkValueReturn}

                //From return
                formReturnAdress = {formReturnAdress}
                formReturnAdress2 = {formReturnAdress2}
                formReturnPostalCode = {formReturnPostalCode}
                formReturnInfo = {formReturnInfo}
                selectedReturnAdressCity = {selectedAdressCityReturn}
                selectedReturnAdressState = {selectedStateReturn}

                dewalServiceIdToSend = {dewalServiceIdToSend}
                dewalServiceIdToReturn = {dewalServiceIdToReturn}

                DHLidSelected = {DHLidSelected} //return
                DLidOrigin = {DLidOrigin}
                />
                
            </div>
        );
    }

    const renderConfirmation = ()=>{
        return(
            <div >
                <Confirmation
                productIdToSend = {productIdToSend}

                dewalServiceIdToSend = {dewalServiceIdToSend}
                origin_adress = {formOriginAdress}
                origin_description = {formOriginAdress2}

                origin_state = {selectedStateOrigin}
                origin_city =  {selectedAdressCityOrigin}


                checkValue = {checkValue}
                checkValueReturn = {checkValueReturn}

                //From return
                formReturnAdress = {formReturnAdress}
                formReturnAdress2 = {formReturnAdress2}

                selectedReturnAdressCity = {selectedAdressCityReturn}
                selectedReturnAdressState = {selectedStateReturn}

                DHLidSelected = {DHLidSelected} //return
                DLidOrigin = {DLidOrigin}
                
                FinalData = {FinalData}/>
            </div>
        );
    }

    
    //TABS
    return (
        <div>
            <HeaderMain />
            <div className="Center">
                <HeaderDewalt />
            </div> 
            <div className="my-5">
                <div className="row tab-stl">
                    <TabMenu 
                        value = {valuetab}
                        tabaction={() =>{setValuetab('Tu Herramienta')}}
                        tabaction2={() =>{setValuetab('Tu compra')}}
                        tabaction3={() =>{setValuetab('Tu envio de origen')}}
                        tabaction4={() =>{setValuetab('Tu envio de regreso')}}
                        tabaction5={() =>{setValuetab('Tus datos')}}
                        tabaction6={() =>{setValuetab('Confirmación')}}
                        value = {valueOptiontab}
                        handleChange = {handleChangeTab}

                    />
                </div>   
                <div>
                    {valuetab === 'Tu Herramienta' && renderToolsPage(setValuetab)}
                    {valuetab === 'Tu compra' && renderPurchasePage()}
                    {valuetab === 'Tu envio de origen' && renderOriginShipment()}
                    {valuetab === "Tu envio de regreso" && renderReturnShipment()}
                    {valuetab === "Tus datos" && renderUserData()}
                    {valuetab === "Confirmación" && renderConfirmation()}
                </div>
            </div>
            <div className="EmptySpace1"></div>
            <FooterMain/>
        </div>
    );
}   
