import React from 'react';
import '../../constants/Global.css'
import '../../pages/style/mainPage.css'
import 'bootstrap/dist/css/bootstrap.css'


import FooterIcon1 from '../../images/Footer-icon1.png'
import FooterIcon2 from '../../images/Footer-icon2.png'
import FooterIcon3 from '../../images/Footer-icon3.png'
import Code from '../../images/CODE.png'
import Code_dewalt from '../../images/Scan.png'
export default function FooterInfo (){
    return(
        <div className="background-black py-5">
            <h2 className="h2-stl white-color Center my-5"><strong>¿TIENES MÁS DUDAS?</strong></h2>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-12 col-md-4 py-3 Center yellow-border">
                        <div className="container">
                            <div className="row">
                                <div className="col-8 col-md-7">
                                    <h3 className="h3-stl-footer white-color Rigth">Escribenos</h3>
                                </div>
                                <div className="col-3 Left pt-1">
                                    <img  src={FooterIcon1} alt="Footer Icon1" ></img>
                                </div>
                                <div className="col-1 col-md-0"></div>
                            </div>
                        </div>
                        <h4 className="h4-stl-footer white-color">support@dewalt.com.mx</h4>       
                    </div>
                        <div className="col col-md-4 py-3 Center yellow-border" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-8 col-md-7">
                                        <h3 className="h3-stl-footer white-color Rigth">Llamanos</h3>  
                                    </div>
                                    <div className="col-3 Left pt-1">
                                        <img  src={FooterIcon2} alt="Footer Icon2" ></img> 
                                    </div>
                                    <div className="col-1"></div>
                                </div>
                            </div>    
                            <h4 className="h4-stl-footer-pad white-color Left">8008472312<br/>Lunes a Viernes: 8am a 7pm<br/>Sábado: 8am a 12pm</h4>           
                        </div>
                    <div className="col-12 col-md-4 py-3 Center">
                        <div className="container">
                            <div className="row">
                                <div className="col-8 col-md-7">
                                    <h3 className="h3-stl-footer white-color Rigth">WhatsApp</h3> 
                                </div>
                                <div className="col-3 Left pt-1">
                                    <img  src={FooterIcon3} alt="Footer Icon3" ></img>
                                </div>
                                <div className="col-1 col-md-2"></div>
                            </div>
                        </div>    
                        <h4 className="h4-stl-footer-pad white-color Left">+ 1 209 409 3173<br/>Escanee el código para contactarnos</h4>               
                    </div>
                </div>
            </div>
            <img className="flex-left" src={Code_dewalt} style={{width:'150px', height:'150px'}} alt="Code" ></img>
        </div>
    );
}
