import React, { useState } from 'react';
import { Datepicker } from '@zendeskgarden/react-datepickers';
import { Field, Label, Input } from '@zendeskgarden/react-forms';
import './styles/DateSelected.css'

const DateSelect = (props) => {
    const [state, setState] = useState(new Date());
    const dateFormatter = new Intl.DateTimeFormat('UTC', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
    // const mounthDate = state.getUTCMonth()+1;
    // const dayDate = state.getDate();
    // const fecha = state.toString();
    // console.log(dayDate)
    return (
        <div className="marg-5">
            <div className="row">
                <div className="col-12">
                    <Field >
                        <Label>Fecha de tu compra (dd/mm/yyyy)</Label>
                        <Datepicker
                        value={props.state}
                        onChange={props.setStateAction}
                        formatDate={date => props.dateFormatter.format(date)}
                    >
                        <Input style={{
                            backgroundColor: '#000',
                            color: 'white',
                            borderTop: '4px solid #FEBD18 ',
                            borderRadius:'0',
                            fontFamily: 'IBM Plex Sans Condensed',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '18px',
                            lineHeight: '23px',
                            width:'330px',
                            paddingLeft:'30px',
                            display:'block'
                        }} />
                        </Datepicker >
                    </Field>
                </div>
            </div>
        </div>
            
        );
};

export default DateSelect;