import React, { useRef, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { Item, Menu, Label, Field, Dropdown, Autocomplete } from '@zendeskgarden/react-dropdowns';
import { Row, Col } from '@zendeskgarden/react-grid';

//CSS
import '../../constants/Global.css'
import './styles/DropDown.css' 


export default function DropDown (props){
    // const selectedItem = props.selectedItem
    const [inputValue, setInputValue] = useState('');
    const [matchingOptions, setMatchingOptions] = useState(props.options);


        const filterMatchingOptionsRef = useRef(
            debounce((value) => {
            const matchedOptions = props.options.filter(
                option => option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
            );
            setMatchingOptions(matchedOptions);
            }, 300)
        );
    
    useEffect(() => {
        filterMatchingOptionsRef.current(inputValue);
        
    }, [inputValue]);

    let filteredDropdoun = ""
    if (props.options){
         filteredDropdoun = props.options.filter((option)=>{
            return option.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1 ;
        })
    }
    


    return (
        <Row justifyContent="left" >
            <Col sm={5}  >
                <Dropdown
                inputValue={inputValue}
                selectedItem={props.selectedItem}
                onSelect={props.onSelect}
                onInputValueChange={(value) => setInputValue(value)}
                downshiftProps={{ defaultHighlightedIndex: 0 }}
                >
                <Field >
                    <Label hidden >Accessibly hidden label</Label>
                    <Autocomplete 
                    style = {{backgroundColor: '#000',
                    color: 'white',
                    borderTop: '4px solid #FEBD18 ',
                    borderRadius:'0',
                    fontFamily: 'IBM Plex Sans Condensed',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '18px',
                    lineHeight: '23px',
                    width:'300px',
                    paddingLeft:'30px'
                    }}>{props.selectedItem}</Autocomplete>

                </Field>
                <Menu style = {{backgroundColor: '#FFF',
                    color: '#818181',
                    borderRadius:'0',
                    fontFamily: 'IBM Plex Sans Condensed',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '18px',
                    lineHeight: '23px',

                    }}
                >
                    {filteredDropdoun.length ? (
                    filteredDropdoun.map(option => (
                        <Item key={option} value={option}>
                        <span>{option}</span>
                        </Item>
                    ))
                    ) : (
                    <Item disabled>No matches found</Item>
                    )}
                </Menu>
                </Dropdown>
            </Col>

        </Row>

    );
}   
