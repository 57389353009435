import React, { useState } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Modal, Header, Body, Footer, FooterItem, Close } from '@zendeskgarden/react-modals';
import { Row, Col } from '@zendeskgarden/react-grid';
import 'bootstrap/dist/css/bootstrap.css'

import Logo1 from '../../images/Logo1.png'
import Logo2 from '../../images/Logo2.png'
import Logo3 from '../../images/Logo3.png'
import Logo4 from '../../images/Logo4.png' 

import '../../constants/Global.css'
import './styles/Popup.css'
const Example = (props) => {
    const [visible, setVisible] = useState(false);

    return (
        <div className="row" style={{width:"800px"}}>
            <div className="col-12 Center">
                    {props.visible && (
                    <Modal className="popupBox" onClose={props.onClose}>
                        <div className="py-2"></div>
                        <h3 className="h3-stl-footer Center py-3">¿QUIERES ARREGLAR TU HERRAMIENTA?</h3>
                        <h4 className="h5-stl Center p-y5">No te preocupes, tenemos los mejores Centros de Servicio del país para mantener tu <br/>herramienta en excelente estado.</h4>
                        <h4 className="h5-stl Center p-y5 py-4">HAZ CLICK ABAJO Y COMUNICATE CON ELLOS</h4>
                        <div className="py-1"></div>
                        <div className="row">
                        <div className="col-12 col-md-2"></div>
                            <div className="col-12 col-md-2 Center">
                                <a href="https://www.dwmx.mx/index.php?route=common/home" className="noGarantyShadow" target="_blank"><img src={Logo1}></img></a>
                            </div>
                            <div className="col-12 col-md-8">
                            <div className="py-1"></div>
                                <h6 className="h6-stl">Herramientas y Equipos Profesionales S.A de C.V.</h6>
                                <h6 className="h6-stl"><a href="https://www.dwmx.mx/index.php?route=common/home" style={{textDecoration:'none'}}>www.dwmx.mx</a></h6>
                            </div>
                        </div>
                        <div className="py-3"></div>
                        <div className="row">
                            <div className="col-12 col-md-2"></div>
                            <div className="col-12 col-md-2 Center">
                                <a href="https://refaccionesexpressdelnorte.mx/" className="noGarantyShadow" target="_blank"><img src={Logo2}></img></a>
                            </div>
                            <div className="col-12 col-md-8">
                                <div className="py-1"></div>
                                <h6 className="h6-stl">Refracciones Express del Norte.</h6>
                                <h6 className="h6-stl"><a href="https://refaccionesexpressdelnorte.mx/" target="_blank" style={{textDecoration:'none'}}>www.refaccionesexpressdelnorte.mx</a></h6>
                            </div>
                        </div>
                        <div className="py-3"></div>
                        <div className="row">
                            <div className="col-12 col-md-2"></div>
                            <div className="col-12 col-md-2 Center">
                                <a href="https://www.latiendadeherramientas.com/" className="noGarantyShadow" target="_blank"><img src={Logo3}></img></a>
                            </div>
                            <div className="col-12 col-md-8">
                                <div className="py-1"></div>
                                <h6 className="h6-stl">La Tienda de Herramientas.</h6>
                                <h6 className="h6-stl"><a href="https://www.latiendadeherramientas.com/" target="_blank" style={{textDecoration:'none'}}>www.latiendadeherramientas.com</a></h6>
                            </div>
                        </div>
                        <div className="py-3"></div>
                        <div className="row">
                            <div className="col-12 col-md-2"></div>
                            <div className="col-12 col-md-2 Center">
                                <a href="https://www.hpc.com.mx/" className="noGarantyShadow" target="_blank"><img src={Logo4}></img></a>
                            </div>
                            <div className="col-12 col-md-8">
                                <div className="py-1"></div>
                                <h6 className="h6-stl">Herramientas Portátiles.</h6>
                                <h6 className="h6-stl"><a href="https://www.hpc.com.mx/" target="_blank" style={{textDecoration:'none'}}>www.hpc.com.mx</a></h6>
                            </div>
                        </div>
                        <div className="py-4"></div>
                        
                        <Footer>
                            <FooterItem>
                                <Button onClick={props.onClose} isBasic>
                                Cancel
                                </Button>
                            </FooterItem>
                            
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                    )}
            </div>
        </div>
        
    );
};

export default Example;