import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Field, Hint, Input, FileUpload } from '@zendeskgarden/react-forms';
import Dropzone,{IDropzoneProps, ISubmitButtonProps, Preview} from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import '../styles/purchase.css'
import { width } from 'dom-helpers';


const FileAttachement = (props) => {
    const getUploadParams = () => {
    return { url: 'https://httpbin.org/post' }
    }

    const handleSubmit = (files, allFiles) => {
        // console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    } 


  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   accept: ['image/jpeg', 'image/png', 'image/pdf'],
  //   onDrop
  // });

    return (
        <Row>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={props.handleChangeStatus}
                onSubmit={handleSubmit}
                maxFiles={1}
                accept="image/*,.pdf"
            
                styles={{ dropzone: { minHeight: '50px', maxHeight: '200px',minWidth:'340px' ,width:'400px',overflow:'hidden',background:'#F2F2F2',borderRadius:'0',marginLeft:'0'},
                // previewImage:{width:'1000px', height:'200px'},
                dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {})}}

                inputContent={(files, extra) => (extra.reject ? 'Formato no válido' : 'Cargar factura (jpg,png o pdf)')}
                SubmitButtonComponent={null}
            
            />
            {/* <Preview></Preview> */}
            <div className="col-12 col-md-12 py-3" style={{width:'500px !important',textAlign:'left'}}>
                <Hint style={{width:250,fontWeight:'bold',color:'#469712',textAlign:'left'}}>
                    {`Archivo cargado:${props.fileName}`}
                </Hint>
                {/* <Hint style={{width:150 }}>
                    Formatos permitidos: JPG, PNG, y PDF.
                </Hint> */}
            </div>
        </Row>
    );
};

export default FileAttachement;