import React, {useState, useEffect} from 'react';
import WarrantyButton from '../atoms/WarrantyButton';
import 'bootstrap/dist/css/bootstrap.css'
import '../../constants/Global.css'
import '../styles/Confirmation.css'
import {Link, Redirect} from 'react-router-dom'

export default function Confirmation (props){
    var OriginName = ''
    var OriginAdress = ''
    var OriginState = ''
    var finalArrayData = ''
    if(props.FinalData){
        finalArrayData = props.FinalData;
    }
    var urlOriginGuide = ''
    if(finalArrayData.result.origin_shipment.shipment_request.LabelImage[0].GraphicImage.url.publicPath){
        urlOriginGuide = finalArrayData.result.origin_shipment.shipment_request.LabelImage[0].GraphicImage.url.publicPath;
    }
    
    

    var ReturnAdress = '';
    var ReturnState = '';

    var finalInfoBox1 = '';
    var finalInfoBox2 = '';
    if (props.checkValue === 1){
        OriginName = "Tienda-Sucursal"
        finalInfoBox1 = "*Si envias tu herramienta DEWALT desde una sucursal no olvides llevar a mano tu número de guía. "
        finalInfoBox2 = "* Recuerda enviar tu herramienta en el empaque original."
}
    if (props.checkValue === 2){
        OriginName = "Domicilio"
        finalInfoBox1 = "* Si envias tu herramienta DEWALT desde tu domicilio por favor imprime la guía y pegala al empaque para poder enviarla."
        finalInfoBox2 = "* Recuerda enviar tu herramienta en el empaque original."
    }
    OriginAdress =`${finalArrayData.result.origin_shipment.address}, ${finalArrayData.result.origin_shipment.description} `
    OriginState = `${finalArrayData.result.origin_shipment.city}, ${finalArrayData.result.origin_shipment.state} `
    ReturnAdress = `${finalArrayData.result.return_shipment.address}, ${finalArrayData.result.return_shipment.description} `
    ReturnState = `${finalArrayData.result.return_shipment.city}, ${finalArrayData.result.return_shipment.state} `
    var ServiceCenterName = finalArrayData.result.service_center.address;
    var ServiceCenterAdress = finalArrayData.result.service_center.social_reason;
    var ServiceCenterState = finalArrayData.result.service_center.state;
    var orderNumber = finalArrayData.result.ticket.id;
    var guideNumber = finalArrayData.result.origin_shipment.shipment_request.ShipmentIdentificationNumber;

    return(
        <div className="my-5 ">
            <div className="Center">
                <h2 className="h2-stl-conf"><strong>GRACIAS POR CONFIAR EN NOSOTROS</strong></h2> 
                <div className="py-2"></div>
                <h3 className="h3-stl-conf">Resumen de tu solicitud</h3>

                <div className="py-2"></div>

                <h4 className="h4-stl-conf"><strong>Información del centro de servicio</strong></h4>
                <h4 className="h4-stl-conf">{ServiceCenterName}</h4>
                <h4 className="h4-stl-conf">{ServiceCenterAdress}</h4>
                <h4 className="h4-stl-conf">{ServiceCenterState}</h4>

                <div className="py-2"></div>
                
                <h4 className="h4-stl-conf"><strong>Tu envio de origen es desde</strong></h4>
                <h4 className="h4-stl-conf">{OriginName}</h4>
                {/* <h4 className="h4-stl-conf">{OriginAdress}</h4> */}
                <h4 className="h4-stl-conf">{OriginAdress.replace("_"," ")}</h4>
                <h4 className="h4-stl-conf">{OriginState}</h4>

                <div className="py-2"></div>

                <h4 className="h4-stl-conf"><strong>Tu envío de regreso se entrega en</strong></h4>
                <h4 className="h4-stl-conf">{ReturnAdress.replace("_"," ")}</h4>
                <h4 className="h4-stl-conf">{ReturnState}</h4>

                <div className="py-2"></div>

                <h4 className="h4-stl-conf"><strong>Tu número de guía es</strong></h4>
                <h4 className="h4-stl-conf">{guideNumber}</h4>

                <div className="py-2"></div>

                <h4 className="h4-stl-conf"><strong>Tu número de solicitud es</strong></h4>
                <h4 className="h4-stl-conf">{orderNumber}</h4>
            </div>
            <div className="py-2"></div>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8 box-footer py-4">
                    <h4 className="h4-stl-conf"><strong>{finalInfoBox1}</strong></h4>
                    <h4 className="h4-stl-conf"><strong>{finalInfoBox2}</strong></h4>
                
                </div>
                <div className="col-2"></div>
            </div>
            <div className="my-5">
                        <div className="row">
                            <div className="col-12 col-md-5 button-pos-r mt-3">
                                <a href={urlOriginGuide} target='_blank' download style={{textDecoration:'none'}}>
                                    <WarrantyButton
                                    label = "DESCARGAR GUÍA DHL"
                                    action = {props.backAction}/>
                                </a>
                            </div>
                            <div className="col-0 col-md-2">
                                
                            </div>
                            <div className="col-12 col-md-5 button-pos-l mt-3">
                                <a href="https://support.dewalt.com/hc/es-es" target='_blank' style={{textDecoration:'none'}}>
                                    <WarrantyButton
                                    label = "ver mis solicitudes"
                                    action = {props.nextAction}/>
                                </a>
                            
                            </div>
                        </div>
                    </div >          
        </div>
        
    )
}
