import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import FormUserData from '../molecules/FormUserData'
import WarrantyButton from '../atoms/WarrantyButton';
import Form from 'react-bootstrap/Form'
import InputForm from '../atoms/InputForm';
import Swal from 'sweetalert2'
import ValidationAlert from '../atoms/ValidationAlert'

import Loading from '../atoms/Loading'
import {homeToSameOrigin, homeToOtherHome,homeToServiceCenter,homeToDhlStore,dhlToSameOrigin,dhlToOtherHome,dhlToServiceCenter,dhlToDhlStore} from '../../api/DewalServices'

import '../../constants/Global.css'
import globalLabels from '../../constants/GlobalText.json'
var finalData = ''

export default function UserData (props){
    // const [estado, setEstado] = useState(false)
    // const isChecked = document.getElementById('default-checkbox').checked;
    // console.log('term',estado.checked)
    //Buton Logi
    const [openLogin, setOpenLogin] = useState(false);
    var currentCompany = props.currentValueCompany;
    var companyToSend = "USUARIO DEWALT"
    if(currentCompany == ''){
        companyToSend = "USUARIO DEWALT"
    }else{
        companyToSend = `${currentCompany}`
    }

    const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'En este momento no hay disponibilidad de envió porfavor intentelo más tarde',
        })
    }
    const alertSuccesGet = ()=>{
        Swal.fire({
            icon: 'success',
            title: '¡¡Envio exitoso!!',
            text: 'Solicitud enviada correctamente',
        })
    }
    const $defaultcheckTerm = document.querySelector("#default-checkbox-term");
    if(document.getElementById("default-checkbox-term")){
        if(props.checkBoxTerm === true){
                    $defaultcheckTerm.checked = true;
        }
    }

    var Month = '01';
    if(props.stateDate.toString().substr(4,3) == 'Jan'){
        Month = '01';
    }else if(props.stateDate.toString().substr(4,3) == 'Feb'){
        Month = '02';
    }else if(props.stateDate.toString().substr(4,3) == 'Mar'){
        Month = '03';
    }else if(props.stateDate.toString().substr(4,3) == 'Apr'){
        Month = '04';
    }else if(props.stateDate.toString().substr(4,3) == 'May'){
        Month = '05';
    }else if(props.stateDate.toString().substr(4,3) == 'Jun'){
        Month = '06';
    }else if(props.stateDate.toString().substr(4,3) == 'Jul'){
        Month = '07';
    }else if(props.stateDate.toString().substr(4,3) == 'Aug'){
        Month = '08';
    }else if(props.stateDate.toString().substr(4,3) == 'Sep'){
        Month = '09';
    }else if(props.stateDate.toString().substr(4,3) == 'Oct'){
        Month = '10';
    }else if(props.stateDate.toString().substr(4,3) == 'Nov'){
        Month = '11';
    }else if(props.stateDate.toString().substr(4,3) == 'Dec'){
        Month = '12';
    }

    var fecha = (`${props.stateDate.toString().substr(11,4)}-${Month}-${props.stateDate.toString().substr(8,2)}`)

    const postHomeToSameOrigin = async () =>{
        setOpenLogin(!openLogin)
        if (props.checkValue == 2){
            if (props.checkValueReturn == 1) {
                try{
                    const response = await homeToSameOrigin(
                        props.productIdToSend, 
                        props.selectedFailure, 
                        fecha,
                        props.invoice,
                        props.ZipCode,
                        props.dewalServiceIdToSend,
                        props.origin_adress,
                        props.origin_description,
                        props.origin_postal_code,
                        props.origin_state,
                        props.origin_city,
                        props.origin_observations,
                        props.user_name,
                        // "052",
                        `+52${props.user_phone}`,
                        props.user_email,
                        companyToSend); 
                        alertSuccesGet() 
                        finalData = response.data
                        props.nextAction()
                    
                    return(response)
                }catch(error){
                    console.log("error post HomeToSameOrigin")
                    console.log(error)
                    alertWrongGet()
                }finally{
                    setOpenLogin(false)
                    
                }   
            } 
            if (props.checkValueReturn == 2) {
                try{
                    const response = await homeToOtherHome(
                        props.productIdToSend, 
                        props.selectedFailure, 
                        fecha,
                        props.invoice,
                        props.ZipCode,
                        props.dewalServiceIdToSend,
                        props.origin_adress,
                        props.origin_description,
                        props.origin_postal_code,
                        props.origin_state,
                        props.origin_city,
                        props.origin_observations,
                        props.formReturnAdress,
                        props.formReturnAdress2,
                        props.formReturnPostalCode,
                        props.selectedReturnAdressState,
                        props.selectedReturnAdressCity,
                        props.formReturnInfo,
                        props.user_name,
                        // "052",
                        `+52${props.user_phone}`,
                        props.user_email,);  
                        alertSuccesGet()
                        finalData = response.data
                        props.nextAction()
                    return(response)
                }catch(error){
                    console.log("error post homeToOtherHome")
                    console.log(error)
                    alertWrongGet()
                }finally{
                    setOpenLogin(false)
                }   
            }
            if (props.checkValueReturn == 3) {
                try{
                    const response = await homeToServiceCenter(
                        props.productIdToSend, 
                        props.selectedFailure, 
                        fecha,
                        props.invoice,
                        props.ZipCode,
                        props.dewalServiceIdToSend,
                        props.origin_adress,
                        props.origin_description,
                        props.origin_postal_code,
                        props.origin_state,
                        props.origin_city,
                        props.origin_observations,
                        props.user_name,
                        // "052",
                        `+52${props.user_phone}`,
                        props.user_email);  
                        alertSuccesGet()
                        finalData = response.data
                        props.nextAction()
                    return(response)
                }catch(error){
                    console.log("error post homeToServiceCenter")
                    console.log(error)
                    alertWrongGet()
                }finally{
                    setOpenLogin(false)
                    
                }   
            }
            if (props.checkValueReturn == 4) {
                try{
                    const response = await homeToDhlStore(
                        props.productIdToSend, 
                        props.selectedFailure, 
                        fecha,
                        props.invoice,
                        props.ZipCode,
                        props.dewalServiceIdToSend,
                        props.origin_adress,
                        props.origin_description,
                        props.origin_postal_code,
                        props.origin_state,
                        props.origin_city,
                        props.origin_observations,
                        props.DHLidSelected,
                        props.user_name,
                        // "052",
                        `+52${props.user_phone}`,
                        props.user_email);  
                        alertSuccesGet()
                        finalData = response.data
                        props.nextAction()
                    return(response)
                }catch(error){
                    console.log("error post homeToDhlStore")
                    console.log(error)
                    alertWrongGet()
                }finally{
                    setOpenLogin(false)
                    
                }   
            }
        }
        if (props.checkValue == 1){
            if (props.checkValueReturn == 1) {
                try{
                    const response = await dhlToSameOrigin(
                        props.productIdToSend, 
                        props.selectedFailure, 
                        fecha,
                        props.invoice,
                        props.dewalServiceIdToSend,
                        props.DLidOrigin,
                        props.user_name,
                        // "052",
                        `+52${props.user_phone}`,
                        props.user_email); 
                        alertSuccesGet() 
                        finalData = response.data
                        props.nextAction()
                    return(response)
                }catch(error){
                    console.log("error post HomeToSameOrigin")
                    console.log(error)
                    alertWrongGet()
                }finally{
                    setOpenLogin(false)
                    
                }   
            }
            if (props.checkValueReturn == 2) {
                try{
                    const response = await dhlToServiceCenter(
                        props.productIdToSend, 
                        props.selectedFailure, 
                        fecha,
                        props.invoice,                      
                        props.dewalServiceIdToSend,
                        props.DLidOrigin,
                        props.user_name,
                        // "052",
                        `+52${props.user_phone}`,
                        props.user_email); 
                        alertSuccesGet() 
                        finalData = response.data
                        props.nextAction()
                    return(response)
                }catch(error){
                    console.log("error post dhlToServiceCenter")
                    console.log(error)
                    alertWrongGet()
                }finally{
                    setOpenLogin(false)
                    
                }   
            }
            if (props.checkValueReturn == 3) {
                try{
                    const response = await dhlToOtherHome(
                        props.productIdToSend, 
                        props.selectedFailure, 
                        fecha,
                        props.invoice,
                        props.ZipCode,
                        props.dewalServiceIdToSend,
                        props.DLidOrigin,
                        props.formReturnAdress,
                        props.formReturnAdress2,
                        props.formReturnPostalCode,
                        props.selectedReturnAdressState,
                        props.selectedReturnAdressCity,
                        props.formReturnInfo,
                        props.user_name,
                        // "052",
                        `+52${props.user_phone}`,
                        props.user_email); 
                        finalData = response.data
                        alertSuccesGet() 
                        props.nextAction()
                    return(response)
                }catch(error){
                    console.log("error post HomeToSameOrigin")
                    console.log(error)
                    alertWrongGet()
                }finally{
                    setOpenLogin(false)
                    
                }   
            }
            if (props.checkValueReturn == 4) {
                try{
                    const response = await dhlToDhlStore(
                        props.productIdToSend, 
                        props.selectedFailure, 
                        fecha,
                        props.invoice,    
                        props.ZipCode,     
                        props.dewalServiceIdToSend,
                        props.DLidOrigin,
                        props.DHLidSelected,
                        props.user_name,
                        // "052",
                        `+52${props.user_phone}`,
                        props.user_email); 
                        alertSuccesGet() 
                        finalData = response.data
                        props.nextAction()
                    return(response)
                }catch(error){
                    console.log("error post dhlToDhlStore")
                    console.log(error)
                    alertWrongGet()
                }finally{
                    setOpenLogin(false)
                    
                }   
            }
        }
    } 


    const handleOnClick = () =>{
        return(
        postHomeToSameOrigin() )
    }

    
    var ValidationWarningReturnZipcode = false;
    if(props.currentValuePostalCode != ''){
        const digits_only = (currentValuePostalCode) => [...currentValuePostalCode].every(c => '0123456789'.includes(c))
        const numberOnlyValidation = digits_only(props.currentValuePostalCode)
        if (!numberOnlyValidation){
            ValidationWarningReturnZipcode = true;
        }
        else if (props.currentValuePostalCode.toString().length===5){
            ValidationWarningReturnZipcode = false

        }else{
            ValidationWarningReturnZipcode = true 
        }
    }

    var ValidationWarningReturnPhone = false;
    if(props.currentValuePhone != ''){
        const digits_only = (currentValuePhone) => [...currentValuePhone].every(c => '0123456789'.includes(c))
        const numberOnlyValidation = digits_only(props.currentValuePhone)
        if (!numberOnlyValidation){
            ValidationWarningReturnPhone = true;
        }
        else if (props.currentValuePhone.toString().length === 10){
            ValidationWarningReturnPhone = false

        }else{
            ValidationWarningReturnPhone = true 
        }
    }

    var NextButtonLogic = false;

    if(props.currentValueName == '' || props.currentValueEmail == ''
        || props.currentValuePhone == '' || props.checkBoxTerm == false || ValidationWarningReturnPhone == true){
            NextButtonLogic = false;
    }else{NextButtonLogic = true;}

    
    return(
        <div className="my-5">
            <h5 className="h5-stl Left px-5 pb-3"><strong>{globalLabels.UserPage.Title}</strong></h5>
                <div className="pad-l5">
                    <div className="pad-l5">
                    <InputForm
                    type        = "text"
                    name        = "UserName"
                    id          = "UserName"
                    placeholder = "Nombre y Apellido"
                    style       = {{heigth:'106px'}}
                    currentValue = {props.currentValueName}
                    handleOnInputValue = {props.handleOnInputValueName}
                /> 
                <div className="py-2"></div>
                <InputForm
                    type        = "email"
                    name        = "email"
                    id          = "email"
                    placeholder = "Correo Eléctronico"
                    style       = {{heigth:'106px'}}
                    currentValue = {props.currentValueEmail}
                    handleOnInputValue = {props.handleOnInputValueEmail}
                /> 

                <div className="py-2"></div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <InputForm
                            type        = "number"
                            name        = "Phone"
                            id          = "Phone"
                            placeholder = "Teléfono (555) 123-4567"
                            style       = {{heigth:'106px'}}
                            currentValue = {props.currentValuePhone}
                            handleOnInputValue = {props.handleOnInputValuePhone}
                        /> 
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="pad-alert">
                            {ValidationWarningReturnPhone && <ValidationAlert message="Se requiere un número de 10 digitos"/>}
                        </div>
                    </div>
                </div>              

                <div className="py-2"></div>
                        <div  className="my-3">
                        <Form>
                            {['checkbox'].map((type) => (
                                <div key={`default-${type}`} className="mb-3">
                                    <Form.Check
                                        label={<h6 className="h6-stl gray-color">Acepto <a href="https://mx.dewalt.global/terminos-y-condiciones" target="_blank">términos y condiciones</a></h6>}
                                        type={type}
                                        id={`default-${type}-term`}
                                        onClick = {props.actionCheckBox1}
                                        checked = {NextButtonLogic}
                                    
                                    />
                                    <Form.Check
                                        label={<h6 className="h6-stl gray-color">Quiero recibir correos electrónicos con ofertas e información importante </h6>}
                                        type={type}
                                        id={`default-${type}1`}
                                        onClick = {props.actionCheckBox2}
                                    />
                                </div>
                            ))}
                        </Form>
                        
                    </div> 
                    </div>
                </div>
                <div className="my-5">
                        <div className="row">
                            <div className="col-12 col-md-5 button-pos-r mt-3">
                                <WarrantyButton
                                label = "ANTERIOR"
                                action = {props.backAction}/>
                            </div>
                            <div className="col-0 col-md-2">
                                
                            </div>
                            <div className="col-12 col-md-5 button-pos-l mt-3">
                                <WarrantyButton
                                label = "CONFIRMAR ENVÍO"
                                // action = {()=>{props.nextAction
                                //     handleOnClick()}}
                                action = {postHomeToSameOrigin}
                                disabled = {NextButtonLogic == false}/>
                            </div>
                        </div>
                    </div >   
                <Loading
                    open = {openLogin}
                />       
        </div>
        
    )
}
export function SendfinalData (){
    return finalData;
}

