import React, {useState, useEffect,useContext} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import Form from 'react-bootstrap/Form'
import WarrantyButton from '../atoms/WarrantyButton';
import DropDownWhite from '../atoms/DropDownWhite'
import Swal from 'sweetalert2'
import '../../constants/Global.css'
import '../styles/returnShipment.css'
import {getAllStates, getAllCities, getAllSucursal,getServiceCenters} from '../../api/DewalServices'
import InputFormBig from '../atoms/InputFormBig';
import InputForm from '../atoms/InputForm';
import ValidationAlert from '../atoms/ValidationAlert'
import Loading from '../atoms/Loading'
import AppConext from '../../context/context';


var dewalService_ID_origin  = '';
var dewalService_ID_return = '';
var dewalService_zipCode = '';
var DHLId = '';
export default function ReturnShipment (props){
    const {selectedStateReturn,setSelectedStateReturn,selectedAdressCityReturn,setSelectedAdressCityReturn,returnStateId,setReturnStateId} = useContext(AppConext)

    const checkValueReturn = props.checkValueReturn;
    
    const [openLogin, setOpenLogin] = useState(false);
    const [statesArray, setStatesArray] = useState({ result:{
        items:[{
            name:'Estado',
            id: '0',
        }]
    }})
    const [citiesArray, setcitiesArray] = useState({ result:{
        items:[{
            name:'',
            id: '',
            state:''
        }]
    }})

    const [listaCitiesOptions, setlistaCitiesOptions] = useState(citiesArray.result.items.map((arreglo)=>(arreglo.name)))

    const [sucursalArray, setSucursalArray] = useState({ result:{
        items:[{
            name:'',
            state:'',
            street:'',
            number_ext:'',
            number_int:'',
            id:''
        }]
    }})
    const [serviceCenterArray, setServiceCenterArray] = useState({ result:{
        items:[{
            social_reason:'0',
            address:'0',
            phone_1:'0',
            email_1:'0',
            state:'Guerrero',
            coverage:''
        }]
    }})
    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    
    const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo ha salido mal por favor inténtelo mas tarde!',
        })
    }
    const getCities = async (id) =>{
        setOpenLogin(!openLogin)
        try{
            const response = getAllCities(id);  
            const data = (await response).data;
            const citiesoptions = data.result.items.map((arreglo)=>(arreglo.name))
            setcitiesArray(data)
            setlistaCitiesOptions(citiesoptions);
            return(data)
        }catch(error){
            console.log("error get Cities")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    }
    const getStates = async () =>{
        setOpenLogin(!openLogin)
        try{
            const response = getAllStates();  
            const data = (await response).data;
            setStatesArray(data)
            return(data)
        }catch(error){
            console.log("error get subcategory")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    }
    const getSucursal = async () =>{
        setOpenLogin(!openLogin)
        try{
            const response = getAllSucursal();  
            const data = (await response).data;
            setSucursalArray(data)
            return(data)
        }catch(error){
            console.log("error get subcategory")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    }
    const getServiceCenter = async () =>{
        setOpenLogin(!openLogin)
        try{
            const response = getServiceCenters();  
            const data = (await response).data;
            setServiceCenterArray(data)
            return(data)
        }catch(error){
            console.log("error get subcategory")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    }
    const handleOnSelect = async (item) =>{
        setSelectedStateReturn(item)
        setSelectedAdressCityReturn('Ciudad')
        const filteredState = statesArray.result.items.filter((e)=>{
            return removeAccents(`${e.name}`).toLowerCase().includes(removeAccents(item).toLowerCase());
        })
        if (filteredState == '') return
        setReturnStateId(filteredState[0].id);
        await getCities(filteredState[0].id) 
    }

    useEffect(async()=>{
        await getStates();
        await getSucursal();
        await getServiceCenter();
        
        
    }, []);
    //filtrar centro de servicio
    const filteredServiceCenter = serviceCenterArray.result.items.filter((e)=>{
        return removeAccents(`${e.coverage}`).toLowerCase().includes(removeAccents(props.selectedStateOrigin).toLowerCase())   ;
    })
    //filtrar centro de servicio
    const filteredServiceCenterOther = serviceCenterArray.result.items.filter((e)=>{
        return removeAccents(`${e.state}`).toLowerCase().includes(props.selectedStateReturn.toLowerCase())   ;
    })
    //Validacion Zipcode
    var ValidationWarningReturnZipcode = false;
    var ValidationAdress = false;
    var ValidationMunicipality = false;
    var ValidationReferences = false;
    if(props.currentValuePostalCode != ''){
        const digits_only = (currentValuePostalCode) => [...currentValuePostalCode].every(c => '0123456789'.includes(c))
        const numberOnlyValidation = digits_only(props.currentValuePostalCode)
        if (!numberOnlyValidation){
            ValidationWarningReturnZipcode = true;
        }
        else if (props.currentValuePostalCode.toString().length===5){
            ValidationWarningReturnZipcode = false

        }else{
            ValidationWarningReturnZipcode = true 
        }
    }
    if(props.currentValueAdress.length==45){
        ValidationAdress = true
    }
    if(props.currentValueAdress2.length==45){
        ValidationMunicipality = true
    }
    if(props.currentValueInfo.length==45){
        ValidationReferences = true
    }
    //Logic Button
    var NextButtonLogic = false;
    if(props.checkValue == 1){
        if(props.checkValueReturn == 1){NextButtonLogic = true;}
        if(props.checkValueReturn == 2){NextButtonLogic = true;}
        if(props.checkValueReturn == 3){
            if(props.currentValueAdress == '' || props.currentValuePostalCode == ''
                || selectedAdressCityReturn == 'Ciudad' || selectedStateReturn == 'Estado' || ValidationWarningReturnZipcode == true){
                    NextButtonLogic = false
            }else{NextButtonLogic = true;}
        }
        if(props.checkValueReturn == 4){
            if(props.selectedStateReturn === '' || props.selectedSucursalReturn === ''){
                NextButtonLogic = false
            }else{NextButtonLogic = true;}
        }
    }else if(props.checkValue == 2){
        if(props.checkValueReturn == 1){NextButtonLogic = true;}
        if(props.checkValueReturn == 2){
            if(props.currentValueAdress == '' || props.currentValuePostalCode == ''
                || selectedAdressCityReturn == 'Ciudad' || selectedStateReturn == 'Estado' || ValidationWarningReturnZipcode == true){
                    NextButtonLogic = false
            }else{NextButtonLogic = true;}
        }
        if(props.checkValueReturn == 3){NextButtonLogic = true;}
        if(props.checkValueReturn == 4){
            if(props.selectedStateReturn == '' || props.selectedSucursalReturn == ''){
                NextButtonLogic = false
            }else{NextButtonLogic = true;}
        }
    }else{NextButtonLogic = true;
    }
    

    const $defaultradio1 = document.querySelector("#default-radio-r1");
    const $defaultradio2 = document.querySelector("#default-radio-r2");
    const $defaultradio3 = document.querySelector("#default-radio-r3");
    const $defaultradio4 = document.querySelector("#default-radio-r4");
    const $defaultradio21 = document.querySelector("#default-radio-r21");
    const $defaultradio22 = document.querySelector("#default-radio-r22");
    const $defaultradio23 = document.querySelector("#default-radio-r23");
    const $defaultradio24 = document.querySelector("#default-radio-r24");

    if(props.checkValue === 1){
        if(document.getElementById("default-radio-r1")){
            if(props.checkValueReturn === 1){
                $defaultradio1.checked = true;
            }
        }
        if(document.getElementById("default-radio-r2")){
            if(props.checkValueReturn === 2){
                $defaultradio2.checked = true;
            }
        }
        if(document.getElementById("default-radio-r3")){
            if(props.checkValueReturn === 3){
                $defaultradio3.checked = true;
            }
        }
        if(document.getElementById("default-radio-r4")){
            if(props.checkValueReturn === 4){
                $defaultradio4.checked = true;
            }
        }
    }
    if(props.checkValue === 2){
        if(document.getElementById("default-radio-r21")){
            if(props.checkValueReturn === 1){
                $defaultradio21.checked = true;
            }
        }
        if(document.getElementById("default-radio-r22")){
            if(props.checkValueReturn === 2){
                $defaultradio22.checked = true;
            }
        }
        if(document.getElementById("default-radio-r23")){
            if(props.checkValueReturn === 3){
                $defaultradio23.checked = true;
            }
        }
        if(document.getElementById("default-radio-r24")){
            if(props.checkValueReturn === 4){
                $defaultradio24.checked = true;
            }
        }
    }
    //Renders main
    const renderOptionSucursalSelected = () =>{
        return(
            <div className="my-5">
                <h5 className="h5-stl Left pad-l5 pb-3"><strong>Llegó el momento de indicar los datos de tu envio de regreso</strong></h5>
                <div className="pad-l5">
                    <h6 className="h6-stl gray-color pad-l5 mt-4"><strong>Cuando tu producto este listo ¿Dónde quieres que lo enviemos de regreso?</strong></h6> 
                    <div  className="pad-l5">
                        <Form>
                            {['radio'].map((type) => (
                                <div key={`default-${type}`} className="mb-3">
                                    <Form.Check
                                        label={<h6 className="h6-stl gray-color">A la misma sucursal de origen </h6>}
                                        name="group1"
                                        type={type}
                                        id={`default-${type}-r1`}
                                        onClick = {props.actionCheckBox1}
                                    />
                                    <Form.Check
                                        label={<h6 className="h6-stl gray-color">Quiero recogerlo en el centro de servicio </h6>}
                                        name="group1"
                                        type={type}
                                        id={`default-${type}-r2`}
                                        onClick = {props.actionCheckBox2}
                                    />
                                    <Form.Check
                                        label={<h6 className="h6-stl gray-color">Quiero que lo envien a mi domicilio</h6>}
                                        name="group1"
                                        type={type}
                                        id={`default-${type}-r3`}
                                        onClick = {props.actionCheckBox3}
                                    />
                                    <Form.Check
                                        inline
                                        label={<h6 className="h6-stl gray-color">Quiero que lo envíen a otra sucursal </h6>}
                                        name="group1"
                                        type={type}
                                        id={`default-${type}-r4`}
                                        onClick = {props.actionCheckBox4}
                                    />
                                </div>
                            ))}
                        </Form>
                    </div> 
                    <div className="py-1"> </div>
                        
                        {checkValueReturn === 2 && renderSucursalOption2()}
                        {checkValueReturn === 3 && renderSucursalOption3()}
                        {checkValueReturn === 4 && renderSucursalOption4()}

                    <div className="my-5">
                        <div className="row">
                            <div className="col-12 col-md-5 button-pos-r mt-3">
                                <WarrantyButton
                                label = "ANTERIOR"
                                action = {props.backAction}/>
                            </div>
                            <div className="col-0 col-md-2">
                                
                            </div>
                            <div className="col-12 col-md-5 button-pos-l mt-3">
                                <WarrantyButton
                                label = "SIGUIENTE"
                                action = {props.nextAction}
                                disabled = {NextButtonLogic == false}/>
                            </div>
                        </div>
                    </div >          
                </div>
            </div>
        );
    }

    
    const renderOptionAdressSelected = () =>{
        return(
            <div className="my-5">
                <h5 className="h5-stl Left pad-l5 pb-3"><strong>Llegó el momento de indicar los datos de tu envio de regreso</strong></h5>
                <div className="pad-l5">
                    <h6 className="h6-stl gray-color pad-l5 mt-4"><strong>Cuando tu producto este listo ¿Dónde quieres que lo enviemos de regreso?</strong></h6> 
                    <div  className="pad-l5">
                        <Form>
                            {['radio'].map((type) => (
                                <div key={`default-${type}`} className="mb-3">
                                    <Form.Check
                                        label={<h6 className="h6-stl gray-color">Quiero que lo envien al mismo domicilio de origen</h6>}
                                        name="group1"
                                        type={type}
                                        id={`default-${type}-r21`}
                                        onClick = {props.actionCheckBox1}
                                    />
                                    <Form.Check
                                        label={<h6 className="h6-stl gray-color">Quiero que lo envien a otro domicilio</h6>}
                                        name="group1"
                                        type={type}
                                        id={`default-${type}-r22`}
                                        onClick = {props.actionCheckBox2}
                                    />
                                    <Form.Check
                                        label={<h6 className="h6-stl gray-color">Quiero Recogerlo en el centro de servicio </h6>}
                                        name="group1"
                                        type={type}
                                        id={`default-${type}-r23`}
                                        onClick = {props.actionCheckBox3}
                                    />
                                    <Form.Check
                                        inline
                                        label={<h6 className="h6-stl gray-color">Quiero que lo envíen a una sucursal </h6>}
                                        name="group1"
                                        type={type}
                                        id={`default-${type}-r24`}
                                        onClick = {props.actionCheckBox4}
                                    />
                                </div>
                            ))}
                        </Form>
                    </div>         
                    <div className="py-3"> </div>
                        {checkValueReturn === 2 && renderSucursalOption3()}
                        {checkValueReturn === 3 && renderSucursalOption2()}
                        {checkValueReturn === 4 && renderSucursalOption4()}
                    <div className="my-5">
                </div>
                
                    <div className="row">
                        <div className="col-12 col-md-5 button-pos-r mt-3">
                            <WarrantyButton
                            label = "ANTERIOR"
                            action = {props.backAction}/>
                        </div>
                        <div className="col-0 col-md-2">
                        </div>
                        <div className="col-12 col-md-5 button-pos-l mt-3">
                            <WarrantyButton
                            label = "SIGUIENTE"
                            action = {props.nextAction}
                            disabled = {NextButtonLogic == false}/>
                        </div>
                    </div>
                </div >        
            </div>
        );
    }

     //Renders
    const renderSucursalOption2 = () =>{
        return(
            <div>
                <div className="row">
                
                    <div className="col-12 col-md-10 px-5" style={{maxWidth:'1000px'}}>
                        <div className="background-gray info-box">
                            <div className = "px-4 py-4">
                                <h6 className="info-text"><strong>Centro de servicio: </strong>{filteredServiceCenter[0] && filteredServiceCenter[0].social_reason}</h6>
                                <h6 className="info-text"><strong>Dirección: </strong>{filteredServiceCenter[0] &&  filteredServiceCenter[0].address}</h6>
                                <h6 className="info-text"><strong>Estado: </strong>{filteredServiceCenter[0] &&  filteredServiceCenter[0].state}</h6>
                                <h6 className="info-text"><strong>Ciudad: </strong>{filteredServiceCenter[0] &&  filteredServiceCenter[0].city}</h6>
                                <h6 className="info-text"><strong>Colonia: </strong>{filteredServiceCenter[0] &&  filteredServiceCenter[0].colonia}</h6>
                                <h6 className="info-text"><strong>Teléfono: </strong>{filteredServiceCenter[0] &&  filteredServiceCenter[0].phone_1}</h6>
                                <h6 className="info-text"><strong>Correo electrónico: </strong>{filteredServiceCenter[0] &&  filteredServiceCenter[0].email_1}</h6>

                            </div>
                        </div>
                        <div className="py-3"></div>
                    </div>
                    <div className="col-0 col-md-1"></div>
                    <div className="col-12 col-md-5">
                        {/* <div className="background-gray mapa-box">
                            <div className = "px-4 py-4">
                                <h6 className="info-text">Mapa 1</h6>

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
    
    const renderSucursalOption3 = () =>{
        // var listaCitiesOptions = '';

    // if (filteredState != ''){
    //     var stateId = filteredState[0].id;
    //     const filteredcities = citiesArray.result.items.filter((e)=>{
    //         return e.state === stateId;
    //     })
    //     listaCitiesOptions = filteredcities.map((arreglo)=>(arreglo.name))    
    // }else{
    //     listaCitiesOptions = [
    //             '',
    //     ]
    // }
        return(
            <div className="pb-3 pad-l5">
                <h6 className="h6-stl gray-color "><strong>Tu dirección:</strong></h6> 
                <div className="row">
                    <div className="col-12 col-md-3">
                    <InputForm
                        type        = "text"
                        name        = "Direction"
                        id          = "Direction"
                        placeholder = "Dirección"
                        style       = {{heigth:'106px'}}
                        currentValue = {props.currentValueAdress}
                        handleOnInputValue = {props.handleOnInputValueAdress}
                        maxCharactersAllowed = {45}
                    /> 
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="pad-alert">
                        {ValidationAdress && <ValidationAlert message="Máximo 45 caracteres"/>}
                        </div>
                    </div> 
                </div>
                <div className="py-2"></div>
                <div className="row">
                <div className="col-12 col-md-3">
                <InputForm
                    type        = "text"
                    name        = "Direction2"
                    id          = "Direction2"
                    placeholder = "Colonia o Municipio"
                    style       = {{heigth:'106px'}}
                    currentValue = {props.currentValueAdress2}
                    handleOnInputValue = {props.handleOnInputValueAdress2}
                    maxCharactersAllowed = {45}
                /> 
                </div>
                <div className="col-12 col-md-6">
                        <div className="pad-alert">
                        {ValidationMunicipality && <ValidationAlert message="Máximo 45 caracteres"/>}
                        </div>
                    </div> 
                </div>
                <div className="py-2"></div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <InputForm
                            type        = "number"
                            name        = "Postalcode"
                            id          = "Postalcode"
                            placeholder = "Código postal"
                            style       = {{heigth:'106px'}}
                            currentValue = {props.currentValuePostalCode}
                            handleOnInputValue = {props.handleOnInputValuePostalCode}
                        /> 
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="pad-alert">
                            {ValidationWarningReturnZipcode && <ValidationAlert message="Escriba un número postal valido"/>}
                        </div>
                    </div>
                    
                </div>
                <div className="py-2"></div>
                <DropDownWhite
                    options = {listaStateOptions}
                    selectedItem={selectedStateReturn}
                    onSelect={(item) => {
                        handleOnSelect(item)
                    }}/>
                <div className="py-2"></div>
                <DropDownWhite
                    options = {listaCitiesOptions}
                    selectedItem={selectedAdressCityReturn}
                    onSelect={item => {setSelectedAdressCityReturn(item)}}/>
                <div className="py-2"></div>
                <h6 className="h6-stl gray-color pt-2"><strong>Referencias</strong></h6> 
                <div className="row">
                    <div className="col-12 col-md-3">
                <InputFormBig 
                    type        = "text"
                    name        = "Information"
                    id          = "Information"
                    placeholder = "Información adicional"
                    style       = {{heigth:'106px'}}
                    currentValue = {props.currentValueInfo}
                    handleOnInputValue = {props.handleOnInputValueInfo}
                    maxCharactersAllowed = {45}   
                /> 
                </div>
                <div className="col-12 col-md-6">
                        <div className="pad-alert">
                        {ValidationReferences && <ValidationAlert message="Máximo 45 caracteres"/>}
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
    //Sucural Filter by state
    const listaStateOptions = statesArray.result.items.map((arreglo)=>(arreglo.name))
    var listaSucursalOptions = '';
    const filteredStateSucursal = statesArray.result.items.filter((e)=>{
        return `${e.name}`.toLowerCase().includes(props.selectedStateReturn.toLowerCase())   ;
    })
    //DHL
    var filteredSucursalSelected = '';
    
    var DHLname = '';
    var DHLIadress = '';
    var DHLNumInter = '';
    var DHLNumExt = '';
    var DHLcolony = '';
    var DHLmunicipality = '';
    var DHLtextState = '';
    if (filteredStateSucursal != ''){
        var stateName = filteredStateSucursal[0].name;
        const filteredSucursal = sucursalArray.result.items.filter((e)=>{
            return removeAccents(`${e.state}`).toLowerCase().includes(removeAccents(stateName).toLowerCase())   ;
        })
        listaSucursalOptions = filteredSucursal.map((arreglo)=>(arreglo.name))


        filteredSucursalSelected = filteredSucursal.filter((e)=>{
            return `${e.name}`.toLowerCase().includes(props.selectedSucursalReturn.toLowerCase())   ;
        })
        if(filteredSucursalSelected != ''){
            DHLId = filteredSucursalSelected[0].id;
            DHLname = filteredSucursalSelected[0].name;
            DHLIadress = filteredSucursalSelected[0].street;
            if(filteredSucursalSelected[0].number_int == 'NA'){
                DHLNumInter = '';
            }else{
                DHLNumInter = filteredSucursalSelected[0].number_int;
            }
            
            DHLNumExt = filteredSucursalSelected[0].number_ext;
            DHLcolony = filteredSucursalSelected[0].colonia;
            DHLmunicipality = filteredSucursalSelected[0].municipality;
            DHLtextState = filteredSucursalSelected[0].state;

   

            // DHLext = filteredSucursalSelected[0].number_ext;
            // DHLInt = filteredSucursalSelected[0].number_int;

        }
    }else{
        listaSucursalOptions = [
                '',
        ]
    }

    if(props.selectedStateOrigin){
        if(props.selectedStateReturn===''){
            DHLname =  '';
            DHLIadress =  '';
            DHLNumInter = '';
            DHLNumExt =  '';
            DHLcolony =  '';
            DHLmunicipality =  '';
            DHLtextState =  '';

        } 
        if(props.selectedSucursalReturn === ''){
            DHLname =  '';
            DHLIadress =  '';
            DHLNumInter = '';
            DHLNumExt =  '';
            DHLcolony =  '';
            DHLmunicipality =  '';
            DHLtextState =  '';
        }
    }

    // dewalService_ID = filteredServiceCenterOther[0].id;

    if(filteredServiceCenter[0]){
        dewalService_ID_origin = filteredServiceCenter[0].id;
        if (filteredServiceCenterOther[0]){
            dewalService_ID_return = filteredServiceCenterOther[0].id;
            dewalService_zipCode = filteredServiceCenterOther[0].postal_code;
        }        
    }

    
    const renderSucursalOption4 = () =>{
        return(
            <div className="pb-3 pad-l5">
                <h6 className="h6-stl gray-color"><strong>Estado</strong></h6> 
                <DropDownWhite
                options = {listaStateOptions}
                selectedItem={selectedStateReturn}
                onSelect={(item) => {
                    handleOnSelect(item)
                }}/>
                <div className="py-2"></div>
                <h6 className="h6-stl gray-color mt-4"><strong>Selecciona la sucursal a la cual quieres llevar tu producto</strong></h6> 
                <DropDownWhite
                options = {listaSucursalOptions}
                selectedItem={props.selectedSucursalReturn}
                onSelect={props.setSelectedSucursalReturn}/>
                <div className="py-4"></div>
                <div className="row">
                
                    <div className="col-12 col-md-10" style={{maxWidth:'800px'}}>
                        <div className="background-gray info-box">
                            <div className = "px-4 py-4">
                                <h6 className="info-text"><strong>Sucursal: </strong> {DHLname}</h6>
                                <h6 className="info-text"><strong>Dirección: </strong>{`${DHLIadress} , ${DHLNumExt} , ${DHLNumInter}`}</h6>
                                <h6 className="info-text"><strong>Colonia: </strong> {DHLcolony}</h6>
                                <h6 className="info-text"><strong>Municipalidad: </strong> {DHLmunicipality}</h6>
                                <h6 className="info-text"><strong>Estado: </strong> {DHLtextState}</h6>
                                {/* <h6 className="info-text"><strong>Teléfono: </strong>{filteredServiceCenterOther[0].phone_1}</h6>
                                <h6 className="info-text"><strong>Correo electrónico: </strong>{filteredServiceCenterOther[0].email_1}</h6> */}
                            </div>
                        </div>
                        <div className="py-3"> </div>
                    </div>
                    <div className="col-0 col-md-1"></div>
                    <div className="col-12 col-md-5">
                        {/* <div className="background-gray mapa-box">
                            <div className = "px-4 py-4">
                                <h6 className="info-text">Mapa</h6>

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }

        return(
        <div>
            {props.checkValue === 1 && renderOptionSucursalSelected()}
            {props.checkValue === 2 && renderOptionAdressSelected()}
            <Loading
            open = {openLogin}/>
        </div>
        
    );
}
export function dewaltServiceIdOrigin (){
    return dewalService_ID_origin;
}
export function dewaltServiceIdReturn (){
    return dewalService_ID_return;
}
export function dewaltServiceZipCode (){
    return dewalService_zipCode;
}

export function DHLid (){
    return DHLId;
}

