import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import DateSelect from '../atoms/DateSelect';
import {ThemeProvider } from '@zendeskgarden/react-theming';
import WarrantyButton from '../atoms/WarrantyButton';
import FileAttachement from '../atoms/FileAttachement';
import ValidationAlert from '../atoms/ValidationAlert'

import globalLabels from '../../constants/GlobalText.json'

//CSS
import '../../constants/Global.css'
import '../styles/purchase.css'


export default function PurchasePage (props){
    const [currentReceipt, setCurrentReceipt]=useState('5')
    const [fileName, setFileName] = useState('')
    const stateDate = props.stateDate
    const todayDate = new Date();
    // const [stateDate, setStateDate] = useState(new Date());
    const dateFormatter = new Intl.DateTimeFormat('UTC', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
    const mounthDate = stateDate.getUTCMonth()+1;
    const dayDate = stateDate.getDate();
    const yearDate = stateDate.getFullYear();
    var warrantyDate = true;
    var alertStateValidation = false;
    var alertStateValidationNextDay = false;

    if(yearDate < todayDate.getFullYear()-2  && mounthDate < todayDate.getMonth()+2){
        warrantyDate = false;
        alertStateValidation = true;
        
    // }else if(yearDate >= todayDate.getFullYear() && mounthDate >= todayDate.getMonth()+2 && dayDate > todayDate.getDate()+1){
    //     var alertStateValidationNextDay = true;
    //     warrantyDate = false;
    }else{
        if(props.currentReceipt === ''){
            warrantyDate = false;
        }else{warrantyDate = true;}
        
    }


    // var file = ''
    // const getBase64 = () => {
    //     return new Promise((resolve) => {
    //         let fileInfo;
    //         let baseURL = '';
    //       // Make new FileReader
    //         let reader = new FileReader();
    
    //       // Convert the file to base64 text
    //         reader.readAsDataURL(file);
    
    //       // on reader load somthing...
    //         reader.onload = () => {
    //         // Make a fileInfo Object
    //         console.log("Called", reader);
    //         baseURL = reader.result;
    //         console.log(baseURL);
    //         resolve(baseURL);
    //         };
    //         console.log(fileInfo);
    //     });
    // };
    // var status = '';
    // var files = '';
    // const handleChangeStatus = async() => {

    //     if(status==='done'){
    //         const base64File = await getBase64(files[0].file)
    //         console.log(typeof base64File)
    //         setCurrentReceipt(base64File)
    //         setFileName(files[0].file.name)
    //     }
    // }

    return(
        <div className="my-5">
            <h5 className=" Left px-5 pb-5"><strong>{globalLabels.PurchasePage.Title1}</strong></h5>
            <div className="mx-5" style={{display:'flex'}}>
                <div className="row">
                    <div className="col-12 col-md-6">
                    <ThemeProvider>
                        <DateSelect
                        setStateAction = {props.setStateDate}
                        state = {stateDate}
                        dateFormatter = {dateFormatter}/>
                    </ThemeProvider>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="pad-l5 py-4">
                            <div className = {"py-1"}></div>
                            {alertStateValidation && <ValidationAlert message = "La fecha de garantia no debe ser mayor a 3 años"/>}
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="mx-5 my-5 Center">
                {/* <UploadInputs/> */}
                {/* <div className="py-3">
                    
                </div> */}
                <div className="px-2">
                    <div className="ply-5 button-stl">
                        <FileAttachement
                        fileName={props.fileName}
                        handleChangeStatus={props.handleChangeStatus}/>
                    </div>
                </div>
                
                
            </div>
            <div className="my-5">
                <div className="row">
                    <div className="col-12 col-md-5 button-pos-r mt-3">
                            <WarrantyButton
                            label = "ANTERIOR"
                            action = {props.backAction}
                            />
                    </div>
                    <div className="col-0 col-md-2">
                        
                    </div>
                    <div className="col-12 col-md-5 button-pos-l mt-3">
                        <WarrantyButton
                        label = "SIGUIENTE"
                        action = {props.nextAction}
                        disabled = {warrantyDate === false}/>
                    </div>
                </div>
                
            </div >
        </div>
        
    );
}
