import React from 'react';
import '../../constants/Global.css'
import '../../pages/style/mainPage.css'
import 'bootstrap/dist/css/bootstrap.css'

import Icon1 from '../../images/Icon1.png'
import Icon2 from '../../images/Icon2.png'
import Icon3 from '../../images/Icon3.png'

export default function HowWorksSection (){
    return(
        <div className="py-5 Background-yellow">
            <h2 className="h2-stl black-color py-5 Center"><strong>¿CÓMO FUNCIONA?</strong></h2>
            <div className="container">
                <div className="row">
                    <div className="col-0 col-md-1"></div>
                    <div className="col-12 col-md-3 py-3 Center">
                        <img  src={Icon1} alt="Icon1" ></img>
                        <h3 className="h3-stl Center mt-5"><strong>INGRESA A<br/>NUESTRA WEB</strong></h3>
                        <h5 className="h5-stl Center">Completa el formulario con los<br/>datos solicitados</h5>
                    </div>
                    <div className="col-12 col-md-4 py-3 Center">
                        <img  src={Icon2} alt="Icon2" ></img>
                        <h3 className="h3-stl Center mt-5"><strong>ENVÍA TU<br/>HERRAMIENTA</strong></h3>
                        <h5 className="h5-stl Center">Desde tu domicilio o desde una<br/>sucursal seleccionada hacia<br/>nuestros centros de servicio</h5>
                    </div>
                    <div className="col-12 col-md-3 py-3 Center">
                        <img  src={Icon3} alt="Icon3" ></img>
                        <h3 className="h3-stl Center mt-5"><strong>RECIBE TU<br/>HERRAMIENTA</strong></h3>
                        <h5 className="h5-stl Center">Recibe tu herramienta en el lugar<br/>que hayas seleccionado para tu<br/>envío de regreso.</h5>
                    </div>
                        <div className="col-0 col-md-1"></div>
                </div>
            </div>
        </div>
    );
}
