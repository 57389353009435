import React from 'react';
import Button from '@material-ui/core/Button';
import '../styles/defaultButton.css'
export default function WarrantyButton(props) {
    return <Button className="Boton-warranty"
        onClick = {props.action}
        // style = {{background: '#FEBD18'}}
        href = {props.refLink}
        target = "_blank"
        disabled = {props.disabled}
        ><h3 className="h3-stl-button">{props.label}</h3>
    </Button>;
}