import React, { useState } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Modal, Header, Body, Footer, FooterItem, Close } from '@zendeskgarden/react-modals';
import { Row, Col } from '@zendeskgarden/react-grid';
import 'bootstrap/dist/css/bootstrap.css'

import Step1 from '../../images/MisSolicitudes1.png'
import Step2 from '../../images/MisSolicitudes2.png'
import Step3 from '../../images/MisSolicitudes3.png'
import Step4 from '../../images/MisSolicitudes4.png'
import Step5 from '../../images/MisSolicitudes5.png'

import PopUpImg from './PopUpImg';

import '../../constants/Global.css'
import './styles/Popup.css'
const PopUpMyRequest = (props) => {
    const [visible, setVisible] = useState(false);
    const [visibleStep2, setVisibleStep2] = useState(false);
    const [visibleStep3, setVisibleStep3] = useState(false);
    const [visibleStep4, setVisibleStep4] = useState(false);
    const [visibleStep5, setVisibleStep5] = useState(false);
    return (
        <div className="row" style={{width:"800px"}}>
            <div className="col-12 Center">
                    {props.visible && (
                    <Modal className="popupBox2" onClose={props.onClose}>
                        <div className="py-2"></div>
                        <h3 className="h3-stl-footer Center pt-5 pb-4">¿Necesitas saber el estado de tu solicitud?</h3>
                        <div className="row pt-5" style={{alignContent:'center'}}>
                            <div className="col-0 col-lg-1"></div>
                            <div className="col-6 col-lg-2">
                                <div className="imgBox" style={{display:'flex'}}>
                                    <div className="imgNumber">
                                        <h5 style={{paddingLeft:'14px',paddingTop:'7px'}}>1</h5>
                                    </div>
                                    <img src={Step1} onClick={() => setVisible(true)}  style={{width:'100%', height:'80%', borderRadius:'30px',paddingTop:'20px',cursor:'pointer'}}></img>
                                </div>
                                <h4 className="h5-stl Center pt-3 marText mb-5" style={{marginTop:'220px', padding:'0 30px 0 0'}}>Dirigete a nuestro helpcenter haciendo click <a href="https://support.dewalt.com/hc/es-es" className="aPopUp" target="_blank">aqui</a>.</h4>
                            </div>
                            <div className="col-6 col-lg-2">
                                <div className="imgBox" style={{display:'flex'}}>
                                    <div className="imgNumber">
                                        <h5 style={{paddingLeft:'14px',paddingTop:'7px'}}>2</h5>
                                    </div>
                                    <img src={Step2} onClick={() => setVisibleStep2(true)} style={{width:'100%', height:'80%', borderRadius:'30px',paddingTop:'20px',cursor:'pointer'}}></img>
                                </div>
                                <h4 className="h5-stl Center pt-3 marText mb-5" style={{marginTop:'220px', padding:'0 30px 0 0'}}>Haz click en iniciar sesión</h4>
                            </div>
                            <div className="col-6 col-lg-2">
                                <div className="imgBox" style={{display:'flex'}}>
                                    <div className="imgNumber">
                                        <h5 style={{paddingLeft:'14px',paddingTop:'7px'}}>3</h5>
                                    </div>
                                    <img src={Step3} onClick={() => setVisibleStep3(true)} style={{width:'100%', height:'80%', borderRadius:'30px',paddingTop:'20px',cursor:'pointer'}}></img>
                                </div>
                                <h4 className="h5-stl Center pt-3 marText mb-5" style={{marginTop:'220px', padding:'0 30px 0 0'}}>Haz click en obtener contraseña</h4>
                            </div>
                            <div className="col-6 col-lg-2">
                                <div className="imgBox" style={{display:'flex'}}>
                                    <div className="imgNumber">
                                        <h5 style={{paddingLeft:'14px',paddingTop:'7px'}}>4</h5>
                                    </div>
                                    <img src={Step4} onClick={() => setVisibleStep4(true)} style={{width:'100%', height:'80%', borderRadius:'30px',paddingTop:'20px',cursor:'pointer'}}></img>
                                </div>
                                <h4 className="h5-stl Center pt-3 marText" style={{marginTop:'220px', padding:'0 30px 0 0'}}>Escribe tu nuevo contraseña </h4>
                            </div>
                            <div className="col-6 col-lg-2">
                                <div className="imgBox" style={{display:'flex'}}>
                                    <div className="imgNumber">
                                        <h5 style={{paddingLeft:'14px',paddingTop:'7px'}}>5</h5>
                                    </div>
                                    <img src={Step5} onClick={() => setVisibleStep5(true)} style={{width:'100%', height:'80%', borderRadius:'10px',paddingTop:'20px',cursor:'pointer'}}></img>
                                </div>
                                <h4 className="h5-stl Center pt-3 marText mb-5" style={{marginTop:'220px', padding:'0 30px 0 0'}}> Haz click en mis actividades </h4>
                            </div>
                            <div className="col-0 col-lg-1"></div>
                            
                        </div>
                        
                        <div className="py-4"></div>
                        
                        <Footer>
                            <FooterItem>
                                <Button onClick={props.onClose} isBasic>
                                Cancel
                                </Button>
                            </FooterItem>
                            
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                    )}
            </div>
            <PopUpImg
            visible = {visible}
            onClose= {() =>setVisible(false)}
            image = {Step1}/>
            <PopUpImg
            visible = {visibleStep2}
            onClose= {() =>setVisibleStep2(false)}
            image = {Step2}/>
            <PopUpImg
            visible = {visibleStep3}
            onClose= {() =>setVisibleStep3(false)}
            image = {Step3}/>
            <PopUpImg
            visible = {visibleStep4}
            onClose= {() =>setVisibleStep4(false)}
            image = {Step4}/>
            <PopUpImg
            visible = {visibleStep5}
            onClose= {() =>setVisibleStep5(false)}
            image = {Step5}/>
        </div>
        
    );
};

export default PopUpMyRequest;