import {axios as http} from "../frameworks_and_drivers/Axios"

import { BASE_URL, TOKEN } from "../config/settings.json";

const opts = {
    headers:{
        Authorization: `Bearer ${TOKEN}`
    }
}
const opts2 = {
    headers:{
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "multipart/form-data"
    }
}
export const getAllCategories = async () => {
    const URL=`${BASE_URL}/v1/categories/get-all`;
    const response = await http.get(URL,opts);
    return response;
}

export const getAllSubCategories = async () => {

    const URL=`${BASE_URL}/v1/subcategories/get-all?`;
    const response = await http.get(URL,opts);
    return response;
}

export const getAllProducts = async () => {
    const URL=`${BASE_URL}/v1/products/get-all`;
    const response = await http.get(URL,opts);
    return response;
}

export const getAllFailureReasons = async () => {

    const URL=`${BASE_URL}/v1/failure-reasons/get-all`;
    const response = await http.get(URL,opts);
    return response;
}

export const getAllStates = async () => {
    const URL=`${BASE_URL}/v1/states/get-all`;
    const response = await http.get(URL,opts);
    return response;
}

export const getAllCities = async (stateId) => {
    const URL=`${BASE_URL}/v1/cities/get-all?state=${stateId}`;
    const response = await http.get(URL,opts);
    return response;
}

export const getAllSucursal = async () => {
    const URL=`${BASE_URL}/v1/dhl-locations/get-all`;
    const response = await http.get(URL,opts);
    return response;
}

export const getServiceCenters = async () => {
    const URL=`${BASE_URL}/v1/service-centers/get-all`;
    const response = await http.get(URL,opts);
    return response;
}

export const getByTraking = async (objectId) => {
    const URL=`${BASE_URL}/v1/dewalt-services/get-by-tracking/${objectId}`;
    const response = await http.get(URL,opts);
    return response;
}

export const homeToSameOrigin = async (
    product_id,
    failure_reason,
    purshased_at,
    invoice,
    zipcode,
    service_center_id,
    origin_adress,
    origin_description,
    origin_postal_code,
    origin_state,
    origin_city,
    origin_observations,
    user_name,
    // user_lada,
    user_phone,
    user_email,
    user_company
    ) => { 

        let bodyFormData = new FormData()
        bodyFormData.append("product",product_id)
        bodyFormData.append("failure_reason",failure_reason)
        bodyFormData.append("purshased_at",purshased_at)
        bodyFormData.append("invoice",invoice)
        bodyFormData.append("zipcode",zipcode)
        bodyFormData.append("service_center",service_center_id)
        bodyFormData.append("origin_shipment",JSON.stringify({
            shipping_place: "home",
            address: origin_adress,
            description: origin_description,
            postal_code: origin_postal_code,
            state: origin_state,
            city: origin_city,
            observations: origin_observations
        }))
        bodyFormData.append("return_shipment",JSON.stringify({
            same_origin: true
        }))
        bodyFormData.append("user",JSON.stringify({
            name: user_name,
            // lada: user_lada,
            phone: user_phone,
            enterprise:"USUARIO DEWALT",
            email: user_email
        }))

    const URL=`${BASE_URL}/v1/dewalt-services/create-one`;
    const response = await http.post(URL,bodyFormData,opts);
    return response;
}

export const homeToOtherHome = async (
    product_id,
    failure_reason,
    purshased_at,
    invoice,
    zipcode,
    service_center_id,
    origin_adress,
    origin_description,
    origin_postal_code,
    origin_state,
    origin_city,
    origin_observations,
    return_adress,
    return_description,
    return_postal_code,
    return_state,
    return_city,
    return_observations,
    user_name,
    // user_lada,
    user_phone,
    user_email,
    ) => {

        let bodyFormData = new FormData()
        bodyFormData.append("product",product_id)
        bodyFormData.append("failure_reason",failure_reason)
        bodyFormData.append("purshased_at",purshased_at)
        bodyFormData.append("invoice",invoice)
        bodyFormData.append("zipcode",zipcode)
        bodyFormData.append("service_center",service_center_id)
        bodyFormData.append("origin_shipment",JSON.stringify({
            shipping_place: "home",
            address: origin_adress,
            description: origin_description,
            postal_code: origin_postal_code,
            state: origin_state,
            city: origin_city,
            observations: origin_observations
        }))
        bodyFormData.append("return_shipment",JSON.stringify({
            shipping_place: "home",
            address: return_adress,
            description: return_description,
            postal_code: return_postal_code,
            state: return_state,
            city: return_city,
            observations: return_observations 
        }))
        bodyFormData.append("user",JSON.stringify({
            name: user_name,
            // lada: user_lada,
            phone: user_phone,
            enterprise:"USUARIO DEWALT",
            email: user_email
        }))

    const URL=`${BASE_URL}/v1/dewalt-services/create-one`;

    const response = await http.post(URL,bodyFormData,opts);
    return response;
}

export const homeToServiceCenter = async (
    product_id,
    failure_reason,
    purshased_at,
    invoice,
    zipcode,
    service_center_id,
    origin_adress,
    origin_description,
    origin_postal_code,
    origin_state,
    origin_city,
    origin_observations,
    user_name,
    // user_lada,
    user_phone,
    user_email,
    ) => {

        let bodyFormData = new FormData()
        bodyFormData.append("product",product_id)
        bodyFormData.append("failure_reason",failure_reason)
        bodyFormData.append("purshased_at",purshased_at)
        bodyFormData.append("invoice",invoice)
        bodyFormData.append("zipcode",zipcode)
        bodyFormData.append("service_center",service_center_id)
        bodyFormData.append("origin_shipment",JSON.stringify({
            shipping_place: "home",
            address: origin_adress,
            description: origin_description,
            postal_code: origin_postal_code,
            state: origin_state,
            city: origin_city,
            observations: origin_observations
        }))
        bodyFormData.append("return_shipment",JSON.stringify({
            shipping_place: "service center",
            place: service_center_id,
            pickup:true
        }))
        bodyFormData.append("user",JSON.stringify({
            name: user_name,
            // lada: user_lada,
            phone: user_phone,
            enterprise:"USUARIO DEWALT",
            email: user_email
        }))

    const URL=`${BASE_URL}/v1/dewalt-services/create-one`;

    const response = await http.post(URL,bodyFormData,opts);
    return response;
}

export const homeToDhlStore = async (
    product_id,
    failure_reason,
    purshased_at,
    invoice,
    zipcode,
    service_center_id,
    origin_adress,
    origin_description,
    origin_postal_code,
    origin_state,
    origin_city,
    origin_observations,
    return_dhl_id,
    user_name,
    // user_lada,
    user_phone,
    user_email,
    ) => {

        let bodyFormData = new FormData()
        bodyFormData.append("product",product_id)
        bodyFormData.append("failure_reason",failure_reason)
        bodyFormData.append("purshased_at",purshased_at)
        bodyFormData.append("invoice",invoice)
        bodyFormData.append("zipcode",zipcode)
        bodyFormData.append("service_center",service_center_id)
        bodyFormData.append("origin_shipment",JSON.stringify({
            shipping_place: "home",
            address: origin_adress,
            description: origin_description,
            postal_code: origin_postal_code,
            state: origin_state,
            city: origin_city,
            observations: origin_observations,
        }))
        bodyFormData.append("return_shipment",JSON.stringify({
            shipping_place: "dhl",
            place: return_dhl_id
        }))
        bodyFormData.append("user",JSON.stringify({
            name: user_name,
            // lada: user_lada,
            phone: user_phone,
            enterprise:"USUARIO DEWALT",
            email: user_email
        }))

    const URL=`${BASE_URL}/v1/dewalt-services/create-one`;

    const response = await http.post(URL,bodyFormData,opts);
    return response;
}

export const dhlToSameOrigin = async (
    product_id,
    failure_reason,
    purshased_at,
    invoice,
    service_center_id,
    origin_shipping_place,
    user_name,
    // user_lada,
    user_phone,
    user_email,
) =>{

    let bodyFormData = new FormData()
    bodyFormData.append("product",product_id)
    bodyFormData.append("failure_reason",failure_reason)
    bodyFormData.append("purshased_at",purshased_at)
    bodyFormData.append("invoice",invoice)
    bodyFormData.append("service_center",service_center_id)
    bodyFormData.append("origin_shipment",JSON.stringify({
        shipping_place: "dhl",
        place: origin_shipping_place,
    }))
    bodyFormData.append("return_shipment",JSON.stringify({
        same_origin:true
    }))
    bodyFormData.append("user",JSON.stringify({
        name: user_name,
        // lada: user_lada,
        phone: user_phone,
        enterprise:"USUARIO DEWALT",
        email: user_email
    }))
    const URL=`${BASE_URL}/v1/dewalt-services/create-one`;

    const response = await http.post(URL,bodyFormData,opts);
    return response;
}

export const dhlToOtherHome = async (
    product_id,
    failure_reason,
    purshased_at,
    invoice,
    zipcode,
    service_center_id,
    origin_shipping_place,
    return_adress,
    return_description,
    return_postal_code,
    return_state,
    return_city,
    return_observations,
    user_name,
    // user_lada,
    user_phone,
    user_email,
    ) => {
        let bodyFormData = new FormData()
        bodyFormData.append("product",product_id)
        bodyFormData.append("failure_reason",failure_reason)
        bodyFormData.append("purshased_at",purshased_at)
        bodyFormData.append("invoice",invoice)
        bodyFormData.append("zipcode",zipcode)
        bodyFormData.append("service_center",service_center_id)
        bodyFormData.append("origin_shipment",JSON.stringify({
            shipping_place: "dhl",
            place: origin_shipping_place,
        }))
        bodyFormData.append("return_shipment",JSON.stringify({
            shipping_place: "home",
            address: return_adress,
            description: return_description,
            postal_code: return_postal_code,
            state: return_state,
            city: return_city,
            observations: return_observations,
        
        }))
        bodyFormData.append("user",JSON.stringify({
            name: user_name,
            // lada: user_lada,
            phone: user_phone,
            enterprise:"USUARIO DEWALT",
            email: user_email
        }))

    const URL=`${BASE_URL}/v1/dewalt-services/create-one`;

    const response = await http.post(URL,bodyFormData,opts);
    return response;
}

export const dhlToServiceCenter = async (
    product_id,
    failure_reason,
    purshased_at,
    invoice,
    service_center_id,
    origin_shipping_place,
    user_name,
    // user_lada,
    user_phone,
    user_email,
    ) => {

        let bodyFormData = new FormData()
        bodyFormData.append("product",product_id)
        bodyFormData.append("failure_reason",failure_reason)
        bodyFormData.append("purshased_at",purshased_at)
        bodyFormData.append("invoice",invoice)
        bodyFormData.append("service_center",service_center_id)
        bodyFormData.append("origin_shipment",JSON.stringify({
            shipping_place: "dhl",
            place: origin_shipping_place,
        }))
        bodyFormData.append("return_shipment",JSON.stringify({
            shipping_place: "service center",
            place: service_center_id,
            pickup:true
        }))
        bodyFormData.append("user",JSON.stringify({
            name: user_name,
            // lada: user_lada,
            phone: user_phone,
            enterprise:"USUARIO DEWALT",
            email: user_email
        }))

    const URL=`${BASE_URL}/v1/dewalt-services/create-one`;

    const response = await http.post(URL,bodyFormData,opts);
    return response;
}

export const dhlToDhlStore = async (
    product_id,
    failure_reason,
    purshased_at,
    invoice,
    zipcode,
    service_center_id,
    origin_shipping_place,
    return_dhl_id,
    user_name,
    // user_lada,
    user_phone,
    user_email
    ) => {

        let bodyFormData = new FormData()

        bodyFormData.append("product",product_id)
        bodyFormData.append("failure_reason",failure_reason)
        bodyFormData.append("purshased_at",purshased_at)
        bodyFormData.append("invoice",invoice)
        bodyFormData.append("zipcode",zipcode)
        bodyFormData.append("service_center",service_center_id)
        bodyFormData.append("origin_shipment",JSON.stringify({
            shipping_place: "dhl",
            place: origin_shipping_place,
        }))
        bodyFormData.append("return_shipment",JSON.stringify({
            shipping_place: "dhl",
            place: return_dhl_id
        }))
        bodyFormData.append("user",JSON.stringify({
            name: user_name,
            // lada: user_lada,
            phone: user_phone,
            enterprise:"USUARIO DEWALT",
            email: user_email
        }))

    const URL=`${BASE_URL}/v1/dewalt-services/create-one`;

    const response = await http.post(URL,bodyFormData,opts2);
    return response;
}
