import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'

// COMPONENTS
import HeaderMain from '../components/molecules/HeaderMain'
import HeaderDewalt from '../components/molecules/HeaderDewalt'
import FooterMain from '../components/molecules/FooterMain'
import SeccionInfo1 from '../components/molecules/SectionInfo1'
import WarrantySection from '../components/molecules/WarrantySection'
import HowWorksSection from '../components/molecules/HowWorksSection';
import ServiceStatus from '../components/molecules/ServiceStatus';
import FooterInfo from '../components/molecules/FooterInfo';

//CSS
import '../constants/Global.css'
import './style/mainPage.css'

export default function MainPage (){
    return (
        <div>
            <HeaderMain />
            <div className="Center">
                <HeaderDewalt />
            </div> 
            {/* INFO INICIAL */}
            <SeccionInfo1/>
            {/* GARANTIA */}
            <WarrantySection/>
            {/* COMO FUNCIONA LA GARANTIA? */}
            <HowWorksSection/>
            {/* ESTADO DE SERVICIO */}
            <ServiceStatus/>
            {/* Footer informacion */}
            <FooterInfo/>
            {/* Footer informacion */}
            <FooterMain/>
        </div>
    );
}   
