import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import { Nav} from 'react-bootstrap';
import './styles/NavMenu.css'
import PopUp from '../atoms/PopUp'
import PopUpMyRequest from './PopUpMyRequest';
import 'reactjs-popup/dist/index.css';


export default function Hook(props) {
    const [visible, setVisible] = useState(false);
    const [visiblePopup2, setVisiblePopup2] = useState(false);
    return( 
    <div >
          <Nav className="justify-content-end" activeKey="/home">
            <Nav.Item>
                <Nav.Link href="/Warranty" className="text-nav-stl mx-3">TENGO GARANTÍA</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link  className="text-nav-stl mx-3" onClick={() => setVisible(true)}>NO TENGO GARANTÍA</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                {/* <Nav.Link href="/TrackingUser" eventKey="link-2" className="text-nav-stl mx-3">RASTREO</Nav.Link> */}
                <Nav.Link href="https://www.dhl.com/mx-es/home/rastreo.html" target="_blank" eventKey="link-2" className="text-nav-stl mx-3">RASTREO</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link onClick={() => setVisiblePopup2(true)} target="_blanck" className="text-nav-stl mx-3">MIS SOLICITUDES</Nav.Link>
            </Nav.Item>
            
        </Nav>
        <PopUp
            visible = {visible}
            onClose= {() =>setVisible(false)}/>
        <PopUpMyRequest
            visible = {visiblePopup2}
            onClose= {() =>setVisiblePopup2(false)}
        />
    </div>
    );
}