import React, {useState, useEffect,useContext} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import Form from 'react-bootstrap/Form'
import WarrantyButton from '../atoms/WarrantyButton';
import DropDown from '../atoms/DropDown';
import DropDownWhite from '../atoms/DropDownWhite'
import Swal from 'sweetalert2'
import {getAllStates, getAllCities, getAllSucursal} from '../../api/DewalServices'
import ValidationAlert from '../atoms/ValidationAlert'
import Loading from '../atoms/Loading'

import InputFormBig from '../atoms/InputFormBig';
import InputForm from '../atoms/InputForm';
import AppConext from '../../context/context';
//CSS
import '../../constants/Global.css'
import '../styles/originShipment.css'
import '../styles/FormOrigin.css'


import globalLabels from '../../constants/GlobalText.json'

var DHLIdOrigin = '';
var Check1State = '';
var Check2State = '';

export default function OriginShipment (props){
    const {selectedStateOrigin,setSelectedStateOrigin,selectedAdressCityOrigin,setSelectedAdressCityOrigin,originStateId,setOriginStateId} = useContext(AppConext)


    const [openLogin, setOpenLogin] = useState(false);
    const [statesArray, setStatesArray] = useState({ result:{
        items:[{
            name:'Estado',
            id: '0',
        }]
    }})
    const [citiesArray, setcitiesArray] = useState({ result:{
        items:[{
            name:'',
            id: '0',
            state:''
        }]
    }})
    const [listaCitiesOptions, setlistaCitiesOptions] = useState(citiesArray.result.items.map((arreglo)=>(arreglo.name)))

    const [sucursalArray, setSucursalArray] = useState({ result:{
        items:[{
            name:'',
            state:'',
            street:'',
            number_ext:'',
            number_int:'',
            id:'',
            colonia:'',
            municipality:'',
            
        }]
    }})
    const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo ha salido mal por favor inténtelo mas tarde!',
        })
    }
    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      }

    useEffect(async()=>{
        await getStates();
        await getSucursal();
    }, []);

    //API CALLS
    const getStates = async () =>{
        setOpenLogin(!openLogin)
        try{
            const response = getAllStates();  
            const data = (await response).data;
            setStatesArray(data)
            return(data)
        }catch(error){
            console.log("error get State")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    }
    //var listaCitiesOptions = citiesArray.result.items.map((arreglo)=>(arreglo.name));
    const getCities = async (id) =>{
        setOpenLogin(!openLogin)
        try{
            const response = getAllCities(id);  
            const data = (await response).data;
            const citiesoptions = data.result.items.map((arreglo)=>(arreglo.name))
            setcitiesArray(data)
            setlistaCitiesOptions(citiesoptions);
            return(data)
        }catch(error){
            console.log("error get Cities")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    }
    
    const getSucursal = async () =>{
        setOpenLogin(!openLogin)
        try{
            const response = getAllSucursal();  
            const data = (await response).data;
            setSucursalArray(data)
            return(data)
        }catch(error){
            console.log("error get Sucursal")
            console.log(error.response)
            alertWrongGet()
        }finally{
            setOpenLogin(false)
        }   
    }


    //Cities Filter by States
    
    const listaStateOptions = statesArray.result.items.map((arreglo)=>(arreglo.name))

    
    const handleOnSelect = async (item) =>{
        setSelectedStateOrigin(item)
        setSelectedAdressCityOrigin('Ciudad')
        const filteredState = statesArray.result.items.filter((e)=>{
            return removeAccents(`${e.name}`).toLowerCase().includes(removeAccents(item).toLowerCase());
        })

        if (filteredState == '') return
        setOriginStateId(filteredState[0].id);
        await getCities(filteredState[0].id) 
    }
    
   
    //Sucural Filter by state
    var listaSucursalOptions = '';
    var filteredSucursalSelected = '';

    var DHLname = '';
    var DHLIadress = '';
    var DHLNumInter = '';
    var DHLNumExt = '';
    var DHLcolony = '';
    var DHLmunicipality = '';
    var DHLtextState = '';

    const filteredStateSucursal = statesArray.result.items.filter((e)=>{
        return (`${e.name}`).toLowerCase().includes(props.selectedStateOrigin.toLowerCase())   ;
    })
    if (filteredStateSucursal != ''){
        var stateName = filteredStateSucursal[0].name;
        const filteredSucursal = sucursalArray.result.items.filter((e)=>{
            return removeAccents(`${e.state}`).toLowerCase().includes(removeAccents(stateName).toLowerCase())   ;
        })
    
        listaSucursalOptions = filteredSucursal.map((arreglo)=>(arreglo.name))

        filteredSucursalSelected = filteredSucursal.filter((e)=>{
            return removeAccents(`${e.name}`).toLowerCase().includes(props.selectedSucursalOrigin.toLowerCase())   ;
        })
        if(filteredSucursalSelected != ''){
            DHLIdOrigin = filteredSucursalSelected[0].id;
            DHLname = filteredSucursalSelected[0].name;
            DHLIadress = filteredSucursalSelected[0].street;
            if(filteredSucursalSelected[0].number_int == 'NA'){
                DHLNumInter = '';
            }else{
                DHLNumInter = filteredSucursalSelected[0].number_int;
            }
            
            DHLNumExt = filteredSucursalSelected[0].number_ext;
            DHLcolony = filteredSucursalSelected[0].colonia;
            DHLmunicipality = filteredSucursalSelected[0].municipality;
            DHLtextState = filteredSucursalSelected[0].state;

        }
    }else{
        listaSucursalOptions = [
                '',
        ]
    }

    if(props.selectedStateOrigin){
        if(props.selectedStateOrigin=='Estado'){
            DHLIdOrigin = '';
            DHLname =  '';
            DHLIadress =  '';
            DHLNumInter = '';
            DHLNumExt =  '';
            DHLcolony =  '';
            DHLmunicipality =  '';
            DHLtextState =  '';

        } 
        if(props.selectedSucursalOrigin === ''){
            DHLIdOrigin = '';
            DHLname =  '';
            DHLIadress =  '';
            DHLNumInter = '';
            DHLNumExt =  '';
            DHLcolony =  '';
            DHLmunicipality =  '';
            DHLtextState =  '';
        }
    }
    
    
    const RenderSucursal = ()=>{
        return(
            <div className="pb-3 px-5">
                <h6 className="h6-stl gray-color">Estado</h6>
                <DropDown
                options = {listaStateOptions}
                selectedItem={selectedStateOrigin}
                onSelect={(item) => {
                    handleOnSelect(item)
                
                }}/> 
                <h6 className="h6-stl gray-color mt-5">{globalLabels.Origin_Shipment.Title}</h6>
                <DropDown
                options = {listaSucursalOptions}
                selectedItem={props.selectedSucursalOrigin}
                onSelect={props.setSelectedSucursalOrigin}/>
                <div className="py-5"></div>
                <div className="row">
                    
                    <div className="col-12 col-md-6" style={{maxWidth:'800px'}}>
                        <div className="background-gray info-box">
                            <div className = "px-4 py-4">
                                <h6 className="info-text"><strong>{globalLabels.Sucursal_Boxes.BoxLavel1} </strong> {DHLname}</h6>
                                <h6 className="info-text"><strong>{globalLabels.Sucursal_Boxes.BoxLavel2} </strong>{`${DHLIadress} , ${DHLNumExt} , ${DHLNumInter} `}</h6>
                                <h6 className="info-text"><strong>{globalLabels.Sucursal_Boxes.BoxLavel3}</strong> {DHLcolony}</h6>
                                <h6 className="info-text"><strong>{globalLabels.Sucursal_Boxes.BoxLavel4} </strong> {DHLmunicipality}</h6>
                                <h6 className="info-text"><strong>{globalLabels.Sucursal_Boxes.BoxLavel5} </strong> {DHLtextState}</h6>
                                {/* <h6 className="info-text"><strong>Teléfono: </strong>{filteredServiceCenterOther[0].phone_1}</h6>
                                <h6 className="info-text"><strong>Correo electrónico: </strong>{filteredServiceCenterOther[0].email_1}</h6> */}
                            </div>
                        </div>
                        <div className="py-3"> </div>
                    </div>
                    <div className="col-0 col-md-1"></div>
                    <div className="col-12 col-md-5">
                        {/* <div className="background-gray mapa-box">
                            <div className = "px-4 py-4">
                                <h6 className="info-text">Mapa</h6>

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }


    var ValidationWarningReturnZipcode = false;
    if(props.currentValuePostalCode != ''){
        const digits_only = (currentValuePostalCode) => [...currentValuePostalCode].every(c => '0123456789'.includes(c))
        const numberOnlyValidation = digits_only(props.currentValuePostalCode)
        if (!numberOnlyValidation){
            ValidationWarningReturnZipcode = true;
        }
        else if (props.currentValuePostalCode.toString().length===5){
            ValidationWarningReturnZipcode = false

        }else{
            ValidationWarningReturnZipcode = true 
        }
    }
    const RenderDomicilio = ()=>{
        return(
            <div className="pb-3 pad-l5">
                <h6 className="h6-stl gray-color pt-3"><strong>{globalLabels.HomeForms.Title1}</strong></h6> 
                <InputForm
                    type        = "text"
                    name        = "Direction"
                    id          = "Direction"
                    placeholder = "Dirección"
                    style       = {{heigth:'106px'}}
                    currentValue = {props.currentValueAdress}
                    handleOnInputValue = {props.handleOnInputValueAdress}
                    maxCharactersAllowed = {45}
                /> 
                <div className="py-2"></div>
                <InputForm
                    type        = "text"
                    name        = "Direction2"
                    id          = "Direction2"
                    placeholder = "Colonia o Municipio"
                    style       = {{heigth:'106px'}}
                    currentValue = {props.currentValueAdress2}
                    handleOnInputValue = {props.handleOnInputValueAdress2}
                    maxCharactersAllowed = {35}
                /> 
                <div className="py-2"></div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <InputForm
                            type        = "number"
                            name        = "Direction2"
                            id          = "Direction2"
                            placeholder = "Código postal"
                            style       = {{heigth:'106px'}}
                            currentValue = {props.currentValuePostalCode}
                            handleOnInputValue = {props.handleOnInputValuePostalCode}
                        /> 
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="pad-alert">
                        {ValidationWarningReturnZipcode && <ValidationAlert message="Escriba un número postal valido"/>}
                        </div>
                    </div> 
                </div>
                <div className="py-2"></div>
                <div className="h4-stl-form">
                    <DropDownWhite
                        
                        options = {listaStateOptions}
                        selectedItem={selectedStateOrigin}
                        onSelect={(item) => {
                            handleOnSelect(item)
                        
                        }}/> 
                    <div className="py-2"></div>
                    <DropDownWhite
                        options = {listaCitiesOptions}
                        selectedItem={selectedAdressCityOrigin}
                        onSelect={item => {setSelectedAdressCityOrigin(item)}}/>      
                    <div className="py-2"></div>
                </div>
                <h6 className="h6-stl gray-color pt-2"><strong>{globalLabels.HomeForms.Title2}</strong></h6> 
                <InputFormBig 
                    type        = "text"
                    name        = "Information"
                    id          = "Information"
                    placeholder = "Información adicional"
                    style       = {{heigth:'106px'}}
                    currentValue = {props.currentValueInfo}
                    handleOnInputValue = {props.handleOnInputValueInfo}
                    maxCharactersAllowed = {34}   
                /> 
            </div>
        )
    }
    
    //Disabled Button
    
    var NextButtonLogic = false;
    if (props.checkValue == 0){
        NextButtonLogic = false;
    }else if(props.checkValue == 1){
        if(props.selectedStateOrigin == 'Estado' || props.selectedSucursalOrigin == ''){
            NextButtonLogic = false;
        }else{NextButtonLogic = true;}
    }else if(props.checkValue == 2){
        if(selectedAdressCityOrigin == 'Ciudad' || props.selectedStateOrigin == 'Estado' || !props.currentValueAdress
                || !props.currentValuePostalCode  || ValidationWarningReturnZipcode){
            NextButtonLogic = false;
        }else{NextButtonLogic = true;}
    }else{NextButtonLogic = true;
    }


    const $defaultradio1 = document.querySelector("#default-radio-1");
    const $defaultradio2 = document.querySelector("#default-radio-2");
    // var Check1State = document.getElementById("default-radio-1").checked;
    // var Check2State = document.getElementById("default-radio-2").checked;
    if(document.getElementById("default-radio-1")){
        if(props.checkValue === 1){
            $defaultradio1.checked = true
        }
        

    }
    if(document.getElementById("default-radio-2")){
        if(props.checkValue === 2){
            $defaultradio2.checked = true
        }
    }

    return(
        <div className="my-5">
            <h5 className="h5-stl Left px-5 pb-3"><strong>Llegó el momento de indicar los datos de tu envio de origen </strong></h5>
            <div className="pad-l5">
                <h6 className="h6-stl gray-color pad-l5 mt-4"><strong>¿Desde donde quieres enviar tu producto?</strong></h6> 
                <div  className="pad-l5">
                    <Form>
                        {['radio'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                                <Form.Check
                                    label={<h6 className="h6-stl gray-color">Sucursal</h6>}
                                    name="group1"
                                    type={type}
                                    id={`default-${type}-1`}
                                    onClick = {props.actionCheckBox1}
                                />
                                <Form.Check
                                    inline
                                    label={<h6 className="h6-stl gray-color">Quiero que la recojan en mi domicilio</h6>}
                                    name="group1"
                                    type={type}
                                    id={`default-${type}-2`}
                                    onClick = {props.actionCheckBox2}
                                />
                            </div>
                        ))}
                    </Form>
                </div>         
                {props.checkValue === 1 && RenderSucursal()}
                {props.checkValue === 2 && RenderDomicilio()}
                
                
                <div className="my-5">
                    <div className="row">
                        <div className="col-12 col-md-5 button-pos-r mt-3">
                            <WarrantyButton
                            label = "ANTERIOR"
                            action = {props.backAction}
                            />
                        </div>
                        <div className="col-0 col-md-2">
                            
                        </div>
                        <div className="col-12 col-md-5 button-pos-l mt-3">
                            <WarrantyButton
                            label = "SIGUIENTE"
                            action = {props.nextAction}
                            disabled = {NextButtonLogic === false }/>
                        </div>
                    </div>
                </div >  
                <div className="py-3"> </div>
            </div>
            <Loading
            open = {openLogin}/>
        </div>
    );
}
export function sendDHLIdOrigin (){
    return DHLIdOrigin;
}


