import React from 'react';
import './styles/input.css'
import '../styles/formGray.css'

function InputGray (props){
    const handleOnInputChange = (event)=>{
        const cliendData = event.target.value;
        props.handleOnInputValue(cliendData)
    } 
    return(
        <div className="h4-stl-form-g">
            <form action ="">
                <input className="input-stl-g"
                    type= {props.type} 
                    name = {props.name} 
                    placeholder = {props.placeholder}
                    id = {props.id}
                    onChange = {(value) =>handleOnInputChange(value)}
                    value = {props.currentValue}
                    >
                    
                </input>
            </form>     
        </div>
    );
}
export default InputGray;