import React ,{ useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'

// COMPONENTS
import HeaderMain from '../components/molecules/HeaderMain'
import HeaderDewalt from '../components/molecules/HeaderDewalt'
import FooterMain from '../components/molecules/FooterMain'
import InputGray from '../components/atoms/InputGray';
import ValidationAlert from '../components/atoms/ValidationAlert'
import {getByTraking} from '../api/DewalServices'
import Swal from 'sweetalert2'
import Loading from '../components/atoms/Loading'
import {useHistory} from 'react-router-dom'

import checkBoxTrue from '../images/radio_button_true.png'
import checkBoxFalse from '../images/radio_button_false.png'
import arrowButton from '../images/Arrow_button.png'
import NextPoints from '../images/NextPoints.png'

//CSS
import '../constants/Global.css'
import './style/mainPage.css'
import '../components/styles/formGray.css'
import './style/tracking.css'
import { counter } from 'react-uid/dist/es5/context';

import exampleTraking from '../api/example-tracking.json'

export default function TrackingPageid (props){
    var guideNumber = props.numberGuide
    let history = useHistory();
    const example = exampleTraking;

    const [valueTraking, setValueTraking] = useState('');
    const [counter, setCounter] = useState(0);
    const [trackingResponse, setTrackingResponse] = useState({result:{
        origin_shipment:{
            address:'',
            description:'',
            shipping_place:'',
            shipment_request:{
                ShipmentIdentificationNumber:''
            },
            tracking_request:{
                AWBInfo:{
                    ArrayOfAWBInfoItem:{
                        AWBNumber:'',
                        ShipmentInfo:{
                            ShipmentEvent:{
                                ArrayOfShipmentEventItem:[{
                                    Date:'',
                                    Time:'',
                                    ServiceEvent:{
                                        EventCode:''
                                    }
                                }]
                            }
                        }
                    }
                }
            }
        },
        return_shipment:{
            address:'',
            description:'',
            shipping_place:'',
            shipment_request:{
                ShipmentIdentificationNumber:''
            },
            tracking_request:{
                AWBInfo:{
                    ArrayOfAWBInfoItem:{
                        AWBNumber:'',
                        ShipmentInfo:{
                            ShipmentEvent:{
                                ArrayOfShipmentEventItem:[{
                                    Date:'',
                                    Time:'',
                                    ServiceEvent:{
                                        EventCode:''
                                    }
                                }]
                            }
                        }
                    }
                }
            }
        },
        destiny_shipment:{
            address:'',
            description:''
        }
    }});
    const [openLogin, setOpenLogin] = useState(false);

    const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error con número de guia!',
        })
    }

    

    const getByTrakingFunction = async () =>{
        setOpenLogin(!openLogin)
        try{
            const response = getByTraking(props.numberGuide);  
            const data = (await response).data;
            setTrackingResponse(data)
            return(data)
        }catch(error){
            console.log("error get getByTraking")
            console.log(error.response)
            alertWrongGet()
            {history.push('/TrackingUser')}
            
        }finally{
            setOpenLogin(false)
        }   
    }
    useEffect(async()=>{
        await getByTrakingFunction(); 
    }, [])

    var state = ''
    var trakingStatusArrayStart = ''
    var trakingStatusArrayProcess = ''
    var trakingStatusArrayFinish = ''
    let dateStart = ''
    let timeStart = ''
    let dateProcess = ''
    let timeProcess = ''
    let dateFinished = ''
    let timeFinished = ''
    
    console.log(trackingResponse)
    if(trackingResponse.result.origin_shipment.shipment_request.ShipmentIdentificationNumber == guideNumber){
        if(trackingResponse.result.origin_shipment){
            console.log('api',trackingResponse.result.origin_shipment.tracking_request)
            if(trackingResponse.result.origin_shipment.tracking_request.Pieces?.PieceInfo?.ArrayOfPieceInfoItem.PieceEvent?.ArrayOfPieceEventItem){
                
    
                trakingStatusArrayFinish = trackingResponse.result.origin_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent?.ArrayOfPieceEventItem[trackingResponse.result.origin_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem.length-1];
                //largo de arreglo de estado
            if(trackingResponse.result.origin_shipment.tracking_request.Pieces?.PieceInfo?.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem.length === 1){
                trakingStatusArrayStart = trackingResponse.result.origin_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem[0]
                dateStart = trakingStatusArrayStart.Date
                timeStart = trakingStatusArrayStart.Time
    
            }
    
            if(trackingResponse.result.origin_shipment.tracking_request.Pieces?.PieceInfo?.ArrayOfPieceInfoItem.PieceEvent?.ArrayOfPieceEventItem.length === 2){
                trakingStatusArrayStart = trackingResponse.result.origin_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem[0]
                trakingStatusArrayProcess = trackingResponse.result.origin_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem[trackingResponse.result.origin_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem.length-1]
    
                dateStart = trakingStatusArrayStart.Date
                timeStart = trakingStatusArrayStart.Time
                dateProcess = trakingStatusArrayProcess.Date
                timeProcess = trakingStatusArrayProcess.Time
            }
    
            if(trackingResponse.result.origin_shipment.tracking_request.Pieces?.PieceInfo?.ArrayOfPieceInfoItem.PieceEvent?.ArrayOfPieceEventItem.length >= 3){
                trakingStatusArrayStart = trackingResponse.result.origin_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem[0]
                trakingStatusArrayProcess = trackingResponse.result.origin_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem[trackingResponse.result.origin_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem.length-2]
    
                dateStart = trakingStatusArrayStart.Date
                timeStart = trakingStatusArrayStart.Time
                dateProcess = trakingStatusArrayProcess.Date
                timeProcess = trakingStatusArrayProcess.Time
                dateFinished = trakingStatusArrayFinish.Date
                timeFinished = trakingStatusArrayFinish.Time
            }
    
            //PU WC OK
            state = trakingStatusArrayFinish.ServiceEvent?.EventCode
            }else{
                state = 'new'
            }
        }else{
            state = 'new'
        }
    }else{
        if(trackingResponse.return_shipment){

            if(trackingResponse.result.return_shipment.tracking_request.Pieces?.PieceInfo?.ArrayOfPieceInfoItem.PieceEvent?.ArrayOfPieceEventItem){
    
                trakingStatusArrayFinish = trackingResponse.result.return_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent?.ArrayOfPieceEventItem[trackingResponse.result.return_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem.length-1];
                //largo de arreglo de estado
            if(trackingResponse.result.return_shipment.tracking_request.Pieces?.PieceInfo?.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem.length === 1){
                trakingStatusArrayStart = trackingResponse.result.return_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem[0]
                dateStart = trakingStatusArrayStart.Date
                timeStart = trakingStatusArrayStart.Time
    
            }
    
            if(trackingResponse.result.return_shipment.tracking_request.Pieces?.PieceInfo?.ArrayOfPieceInfoItem.PieceEvent?.ArrayOfPieceEventItem.length === 2){
                trakingStatusArrayStart = trackingResponse.result.return_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem[0]
                trakingStatusArrayProcess = trackingResponse.result.return_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem[trackingResponse.result.return_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem.length-1]
    
                dateStart = trakingStatusArrayStart.Date
                timeStart = trakingStatusArrayStart.Time
                dateProcess = trakingStatusArrayProcess.Date
                timeProcess = trakingStatusArrayProcess.Time
            }
    
            if(trackingResponse.result.return_shipment.tracking_request.Pieces?.PieceInfo?.ArrayOfPieceInfoItem.PieceEvent?.ArrayOfPieceEventItem.length >= 3){
                trakingStatusArrayStart = trackingResponse.result.return_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem[0]
                trakingStatusArrayProcess = trackingResponse.result.return_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem[trackingResponse.result.return_shipment.tracking_request.Pieces.PieceInfo.ArrayOfPieceInfoItem.PieceEvent.ArrayOfPieceEventItem.length-2]
    
                dateStart = trakingStatusArrayStart.Date
                timeStart = trakingStatusArrayStart.Time
                dateProcess = trakingStatusArrayProcess.Date
                timeProcess = trakingStatusArrayProcess.Time
                dateFinished = trakingStatusArrayFinish.Date
                timeFinished = trakingStatusArrayFinish.Time
            }
    
            //PU WC OK
            state = trakingStatusArrayFinish.ServiceEvent.EventCode
            }else{
                state = 'new'
            }
        }else{
            state = 'new'
        }
    }
    
   

var Placeorigin = ''
var PlaceReturn = ''


if(trackingResponse.result.origin_shipment.shipment_request.ShipmentIdentificationNumber == guideNumber){
    if(trackingResponse.result.origin_shipment.shipping_place === 'home'){
        Placeorigin = 'Domicilio:'
    }
    if(trackingResponse.result.origin_shipment.shipping_place === 'dhl'){
        Placeorigin = 'Sucursal:'
    }
    if(trackingResponse.result.origin_shipment.shipping_place === 'service center'){
        Placeorigin = 'Centro de Servicio:'
    }
    
    if(trackingResponse.result.destiny_shipment.shipping_place === 'home'){
        PlaceReturn = 'Domicilio:'
    }
    if(trackingResponse.result.destiny_shipment.shipping_place === 'dhl'){
        PlaceReturn = 'Sucursal:'
    }
    if(trackingResponse.result.destiny_shipment.shipping_place === 'service center'){
        PlaceReturn = 'Centro de Servicio:'
    }
}else{
    if(trackingResponse.result.destiny_shipment.shipping_place === 'home'){
        Placeorigin = 'Domicilio:'
    }
    if(trackingResponse.result.destiny_shipment.shipping_place === 'dhl'){
        Placeorigin = 'Sucursal:'
    }
    if(trackingResponse.result.destiny_shipment.shipping_place === 'service center'){
        Placeorigin = 'Centro de Servicio:'
    }
    
    if(trackingResponse.result.return_shipment.shipping_place === 'home'){
        PlaceReturn = 'Domicilio:'
    }
    if(trackingResponse.result.return_shipment.shipping_place === 'dhl'){
        PlaceReturn = 'Sucursal:'
    }
    if(trackingResponse.result.return_shipment.shipping_place === 'service center'){
        PlaceReturn = 'Centro de Servicio:'
    }
}

    // if(example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem){

    //     trakingStatusArrayFinish = example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem[example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem.length-1];
    //     //largo de arreglo de estado
    // if(example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem.length === 1){
    //     trakingStatusArrayStart = example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem[example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem.length-1]
    //     dateStart = trakingStatusArrayStart.Date
    //     timeStart = trakingStatusArrayStart.Time

    // }

    // if(example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem.length === 2){
    //     trakingStatusArrayStart = example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem[example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem.length-2]
    //     trakingStatusArrayProcess = example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem[example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem.length-1]

    //     dateStart = trakingStatusArrayStart.Date
    //     timeStart = trakingStatusArrayStart.Time
    //     dateProcess = trakingStatusArrayProcess.Date
    //     timeProcess = trakingStatusArrayProcess.Time
    // }

    // if(example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem.length === 3){
    //     trakingStatusArrayStart = example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem[example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem.length-3]
    //     trakingStatusArrayProcess = example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem[example.origin_shipment.tracking_request.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent.ArrayOfShipmentEventItem.length-2]

    //     dateStart = trakingStatusArrayStart.Date
    //     timeStart = trakingStatusArrayStart.Time
    //     dateProcess = trakingStatusArrayProcess.Date
    //     timeProcess = trakingStatusArrayProcess.Time
    //     dateFinished = trakingStatusArrayFinish.Date
    //     timeFinished = trakingStatusArrayFinish.Time
    // }

    // //PU WC OK
    // state = trakingStatusArrayFinish.ServiceEvent.EventCode
    // }else{
    //     state = 'new'
    // }


    
    const renderState1Tracking = (props) =>{
        return(
            <div >
                <div className="state-box-track">
                        <div className="row pt-4">
                            <div className="col-2" style={{textAlign:'right'}}>
                                <div className="px-1">
                                    <img src={checkBoxTrue} style={{width:'20px',height:'20px'}} className="mt-1"></img>
                                </div>
                                
                                <div className="pt-2"></div>
                                <img src={NextPoints}  style={{width:'25px',height:'25px'}} ></img>
                            </div>
                            <div className="col-10">
                                <h6 className="h6-stl-1 px-3 "><strong>{dateStart} </strong> a las  {timeStart.slice(0,5)}</h6>
                                <h6 className="h6-stl-1 px-3 ">Tu paquete ha sido recogido</h6>
                                <div className="pt-1 border-text"></div>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-2" style={{textAlign:'right'}}>
                                <div className="px-1">
                                    <img src={checkBoxFalse} style={{width:'20px',height:'20px'}} ></img>
                                </div>
                                
                                <div className="pt-2"></div>
                                <img src={NextPoints}  style={{width:'25px',height:'25px'}} ></img>
                            </div>
                            <div className="col-10">
                                <h6 className="h6-stl-1 px-3 gray-color">Tu paquete esta en camino</h6>
                                <div className="pt-1 border-text"></div>
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-2" style={{textAlign:'right'}}>
                                <div className="px-1">
                                    <img src={checkBoxFalse} style={{width:'20px',height:'20px'}} ></img>
                                </div>
                            </div>
                            <div className="col-10">
                                <h6 className="h6-stl-1 px-3 gray-color">Tu paquete esta en camino</h6>
                                <div className="pt-1 border-text"></div>
                            </div>
                        </div>
                        <div className="py-3"></div>
                    </div>
            </div>
        );
    }

    const renderState2Tracking = (props) =>{
        return(
            <div >
                <div className="state-box-track">
                        <div className="row pt-4">
                            <div className="col-2" style={{textAlign:'right'}}>
                                <div className="px-1">
                                    <img src={checkBoxTrue} style={{width:'20px',height:'20px'}} className="mt-1"></img>
                                </div>
                                
                                <div className="pt-2"></div>
                                <img src={NextPoints}  style={{width:'25px',height:'25px'}} ></img>
                            </div>
                            <div className="col-10">
                                <h6 className="h6-stl-1 px-3 "><strong>{dateStart} </strong> a las {timeStart.slice(0,5)}</h6>
                                <h6 className="h6-stl-1 px-3 ">Tu paquete ha sido recogido</h6>
                                <div className="pt-1 border-text"></div>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-2" style={{textAlign:'right'}}>
                                <div className="px-1">
                                    <img src={checkBoxTrue} style={{width:'20px',height:'20px'}} ></img>
                                </div>
                                
                                <div className="pt-2"></div>
                                <img src={NextPoints}  style={{width:'25px',height:'25px'}} ></img>
                            </div>
                            <div className="col-10">
                            <h6 className="h6-stl-1 px-3 "><strong>{dateProcess} </strong> a las {timeProcess.slice(0,5)}</h6>
                                <h6 className="h6-stl-1 px-3 ">Tu paquete esta en camino</h6>
                                <div className="pt-1 border-text"></div>
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-2" style={{textAlign:'right'}}>
                                <div className="px-1">
                                    <img src={checkBoxFalse} style={{width:'20px',height:'20px'}} ></img>
                                </div>
                            </div>
                            <div className="col-10">
                                <h6 className="h6-stl-1 px-3 gray-color">Tu paquete esta en camino</h6>
                                <div className="pt-1 border-text"></div>
                            </div>
                        </div>
                        <div className="py-3"></div>
                    </div>
            </div>
        );
    }

    const renderState3Tracking = (props) =>{
        return(
            <div >
                <div className="state-box-track">
                        <div className="row pt-4">
                            <div className="col-2" style={{textAlign:'right'}}>
                                <div className="px-1">
                                    <img src={checkBoxTrue} style={{width:'20px',height:'20px'}} className="mt-1"></img>
                                </div>
                                
                                <div className="pt-2"></div>
                                <img src={NextPoints}  style={{width:'25px',height:'25px'}} ></img>
                            </div>
                            <div className="col-10">
                                <h6 className="h6-stl-1 px-3 "><strong>{dateStart}</strong> a las  {timeStart.slice(0,5)}</h6>
                                <h6 className="h6-stl-1 px-3 ">Tu paquete ha sido recogido</h6>
                                <div className="pt-1 border-text"></div>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-2" style={{textAlign:'right'}}>
                                <div className="px-1">
                                    <img src={checkBoxTrue} style={{width:'20px',height:'20px'}} ></img>
                                </div>
                                
                                <div className="pt-2"></div>
                                <img src={NextPoints}  style={{width:'25px',height:'25px'}} ></img>
                            </div>
                            <div className="col-10">
                                <h6 className="h6-stl-1 px-3 "><strong>{dateProcess} </strong> a las  {timeProcess.slice(0,5)}</h6>
                                <h6 className="h6-stl-1 px-3 ">Tu paquete esta en camino</h6>
                                <div className="pt-1 border-text"></div>
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-2" style={{textAlign:'right'}}>
                                <div className="px-1">
                                    <img src={checkBoxTrue} style={{width:'20px',height:'20px'}} ></img>
                                </div>
                            </div>
                            <div className="col-10">
                                <h6 className="h6-stl-1 px-3 "><strong>{dateFinished} </strong> a las  {timeFinished.slice(0,5)}</h6>
                                <h6 className="h6-stl-1 px-3 ">Tu paquete fue entregado</h6>
                                <div className="pt-1 border-text"></div>
                            </div>
                        </div>
                        <div className="py-3"></div>
                    </div>
            </div>
        );
    }

    const renderStateNewTrackingState = (props) =>{ 
        return(
            <div >
                {/* <h6 className="h6-stl-1"><strong>Estado actual:</strong></h6> */}
                {/* <h6 className="h6-stl-1"><strong>Siguiente paso:</strong> Tu herramienta será enviada</h6> */}
            </div>
        );
    }
    const renderState1TrackingState = (props) =>{
        return(
            <div >
                <h6 className="h6-stl-1"><strong>Estado actual:</strong> Tu herramienta será enviada</h6>
                <h6 className="h6-stl-1"><strong>Siguiente paso:</strong> Tu herramienta será recogida</h6>
            </div>
        );
    }
    const renderState2TrackingState = (props) =>{
        return(
            <div >
                <h6 className="h6-stl-1"><strong>Estado actual:</strong> Tu herramienta se encuentra en camino</h6>
                <h6 className="h6-stl-1"><strong>Siguiente paso:</strong> Tu herramienta será entragada</h6>
            </div>
        );
    }
    const renderState3TrackingState = (props) =>{
        return(
            <div >
                <h6 className="h6-stl-1"><strong>Estado actual:</strong>Tu herramienta fue entregada</h6>
            </div>
        );
    }

    
   

    function countClicks () {
        if (counter === 0){
            setValueTraking(state)
            setCounter(1)
        }
        if (counter === 1){
            setCounter(0)
            setValueTraking(0)
        }
    }

    return (
        <div>
            <HeaderMain />
            <div className="Center">
                <HeaderDewalt />
            </div> 
            <div className="py-5"></div>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-9">
                    <h3 className="h3-stl Left"><strong>RASTREAR MI ENVÍO</strong></h3>
                    <h6 className="py-3 h6-stl gray-color">Datos de mi envío</h6>
                    <h6 className="h6-stl gray-color">Numero de guía: {guideNumber}</h6>
                    <div style={{display:'flex'}}  className="pt-4">
                            <img src={checkBoxTrue} style={{width:'20px',height:'20px'}}></img>
                            <h6 className="h6-stl-1 px-3"><strong>{Placeorigin}</strong> {trackingResponse.result.origin_shipment.shipment_request.ShipmentIdentificationNumber == guideNumber ? trackingResponse.result.origin_shipment.address : trackingResponse.result.destiny_shipment.address} , {trackingResponse.result.origin_shipment.shipment_request.ShipmentIdentificationNumber == guideNumber ? trackingResponse.result.origin_shipment.description : trackingResponse.result.destiny_shipment.description}</h6>
                    </div>
                    <img src={NextPoints} style={{width:'25px',height:'25px'}}></img>
                    <div style={{display:'flex'}}>
                            <img src={checkBoxFalse} style={{width:'20px',height:'20px'}}></img>
                            <h6 className="h6-stl-1 px-3"><strong>{PlaceReturn}</strong> {trackingResponse.result.origin_shipment.shipment_request.ShipmentIdentificationNumber == guideNumber ? trackingResponse.result.destiny_shipment.address : trackingResponse.result.return_shipment.address} , {trackingResponse.result.origin_shipment.shipment_request.ShipmentIdentificationNumber == guideNumber ? trackingResponse.result.destiny_shipment.description: trackingResponse.result.return_shipment.description}</h6>
                    </div>
                    <div className="py-3"></div>
                        {state === "new" && renderStateNewTrackingState()}
                        {state === "PU" && renderState1TrackingState()}
                        {state === "WC" && renderState2TrackingState()}
                        {state === "OK" && renderState3TrackingState()}
                    <div className="py-4"></div>
                    <div className="dhl-box" style={{display:'flex'}}>
                        <h6 className="h6-stl-1"><strong>Todas las actualizaciones de mi envío</strong></h6>
                        {counter === 0 && <button className="button-traking-state" id="button-tracking" type="button" onClick={() => countClicks()}></button>}
                        {counter === 1 && <button className="button-traking-stateup" id="button-tracking" type="button" onClick={() => countClicks()}></button>}
                    </div>
                    {valueTraking === "PU" && renderState1Tracking()}
                    {valueTraking === "WC" && renderState2Tracking()}
                    {valueTraking === "OK" && renderState3Tracking()}
                    <div className="py-5"></div>
                    <div className="py-5"></div>
                </div>
                <div className="col-2"></div>
            </div>
            <FooterMain/>
                <Loading
                    open = {openLogin}
                />   
        </div>
    );
}   
