import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '../styles/tabs.css'
    
function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
function LinkTab2(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
function LinkTab3(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
function LinkTab4(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
function LinkTab5(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
function LinkTab6(props) {
    return (
        <Tab
            component="a"
            onClick={props.tabAction}
            {...props}
        />
        );
}
export default function NavTabs(props) {

        const [value, setValue] = React.useState(0);
    
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };
    
        return (
        <div className="Tabs-stl" >
            <AppBar position="static"
            style={{justifyContent: 'space-evenly'}}
            >
                <Tabs
                    value={props.value}
                    onChange={props.handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{background:"#FEBD18",color:"#000"}}
                    
                >
                    <LinkTab label="TU HERRAMIENTA" tabAction={props.tabaction} className="tabs-only-stl" disabled ={props.value == 5} />
                    <LinkTab2 label="TU COMPRA" tabAction={props.tabaction2} className="tabs-only-stl" disabled ={props.value == 0 || props.value == 5}/>
                    
                    {/*  disabled = {value!='0'|| value=='0' } */}
                    <LinkTab3 label="TU ENVIO DE ORIGEN" tabAction={props.tabaction3} className="tabs-only-stl" disabled ={props.value == 0 || props.value == 1 || props.value == 5}   />
                    {/* <LinkTab3 label="CENTRO DE SERVICIO" tabAction={props.tabaction4} className="tabs-only-stl" disabled = {value!='2'}   /> */}
                    <LinkTab4 label="TU ENVIO DE REGRESO" tabAction={props.tabaction4} className="tabs-only-stl"  disabled ={props.value == 0 || props.value == 1 || props.value == 2 || props.value == 5} />
                    <LinkTab5 label="TUS DATOS"  tabAction={props.tabaction5} className="tabs-only-stl" disabled ={props.value == 0 || props.value == 1 || props.value == 2 || props.value == 3 || props.value == 5} />
                    <LinkTab6 label="CONFIRMACIÓN"  tabAction={props.tabaction6} className="tabs-only-stl" disabled ={props.value == 0 || props.value == 1 || props.value == 2 || props.value == 3 || props.value == 4 } />
                </Tabs>
            </AppBar>
        </div>
        );
}

