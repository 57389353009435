import React, {useState} from 'react';
import '../../constants/Global.css'
import '../../pages/style/mainPage.css'
import 'bootstrap/dist/css/bootstrap.css'
import {Link} from 'react-router-dom'
import DefaultButton from '../atoms/DefaultButton'

import PopUpMyRequest from '../atoms/PopUpMyRequest';
export default function ServiceStatus (){
    const [visiblePopup2, setVisiblePopup2] = useState(false);
    return(
        <div className="py-5">
            <h2 className="h2-stl black-color Center my-5"><strong>¿NECESITAS SABER EL ESTADO DE TU SERVICIO DEWALT?</strong></h2>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-0 col-md-1"></div>
                    <div className="col-12 col-md-3 py-3 Center">
                        <Link to="/TrackingUser" style={{textDecoration:'none'}}>
                            <DefaultButton
                            label = "QUIERO RASTREAR MI ENVÍO " />
                        </Link>
                    </div>
                    <div className="col col-md-3"></div>
                    <div className="col-12 col-md-3 py-3 Center">
                            <DefaultButton
                            label = "QUIERO SABER EL ESTADO DE MI SOLICITUD"
                            target = '_blanck'
                            action ={() => setVisiblePopup2(true)} />
                    </div>
                    <div className="col-0 col-md-2"></div>
                </div>
            </div>
            <PopUpMyRequest
            visible = {visiblePopup2}
            onClose= {() =>setVisiblePopup2(false)}
            />
        </div>
    );
}
