import React from 'react';
import '../styles/login.css'
import LogoSE from '../../images/LogoSE.png'
import banner from '../../images/banner.jpeg'

export default function HeaderMain (){
    return(
        <div>
            <img className="imgLogoSE" src={banner} alt="header Fondo + logo"></img>
        </div>
    );
}
