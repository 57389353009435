import React from 'react';
import '../../constants/Global.css'
import NavMenu from '../atoms/NavMenu'
import 'bootstrap/dist/css/bootstrap.css'
import {Link} from 'react-router-dom'


import LogoDW from '../../images/DEWALT_Logo.png'
function HeaderMain (){
    return(
        <div className="container mainHeader">
            <div className="row">
                <div className="col-12 col-md-3">
                    <Link to="/">
                        <img className="logoMainHeader" src={LogoDW} alt="Logo DEWALT" ></img>
                    </Link>
                </div>
                <div className="col-12 col-md-9 pt-3">
                    <NavMenu/>
                </div >
            </div>
        </div>
    );
}

export default HeaderMain;