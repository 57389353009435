import React from 'react';
import '../../constants/Global.css'
import '../../pages/style/mainPage.css'
import 'bootstrap/dist/css/bootstrap.css'

export default function SectionInfo1 (){
    return(
        <div className="my-5">
            <div className="pt-5"> </div>
            <h2 className="h2-stl black-color Center"><strong>SERVICIO OFICIAL DEWALT EN TODO EL PAÍS</strong></h2>
            <div className="mt-5"></div>
            <h4 className="h4.stl black-color Center">DEWALT Servicio Express es el único servicio que cubre todo México para mantener tu <br/>herramienta original en excelente estado. </h4>
            <div className="pt-5"> </div>
        </div>
    );
}
