import React, {useState} from 'react'


const useGlobalStates = () => {
    const [selectedCategory, setSelectedCategory] = useState('Selecciona una categoría');
    const [selectedSubCategory, setSelectedSubCategory] = useState('Selecciona una subcategoría');
    const [selectedFailure, setSelectedFailure] = useState('Motivo de falla');
    const [selectedProduct, setSelectedProduct] = useState('Producto');
    const [selectedAdressCityOrigin, setSelectedAdressCityOrigin] = useState('Ciudad');
    const [selectedStateOrigin, setSelectedStateOrigin] = useState('Estado');
    const [originStateId, setOriginStateId] = useState('')
    const [selectedAdressCityReturn, setSelectedAdressCityReturn] = useState('Ciudad');
    const [selectedStateReturn, setSelectedStateReturn] = useState('Estado');
    const [returnStateId, setReturnStateId] = useState('')

    const [categoriesArray, setcategoriesArray] = useState({ result:{
        items:[{
            name:'',
            id: '',
        }]
    }})

    const [subCategoriesArray, setSubCategoriesArray] = useState({ result:{
        items:[{
            name:'',
            id: '',
            category:''
        }]
    }})
    const [failureReasonsArray, setFailureReasons] = useState({ result:{
        items:[{
            name:'',
            id: '',
        }]
    }})

    const [productssArray, setProductsArray] = useState({ result:{
        items:[{
            product_code:'',
            description:'',
            id: '0',
            subcategory:{
                id:''
            },
            image:{
                url:{
                    publicPath:''
                }
            },
            url:''

        }]
    }})
 

    return{
        categoriesArray,setcategoriesArray,
        subCategoriesArray,setSubCategoriesArray,
        failureReasonsArray,setFailureReasons,
        productssArray,setProductsArray,
        selectedCategory, setSelectedCategory,
        selectedSubCategory, setSelectedSubCategory,
        selectedFailure, setSelectedFailure,
        selectedProduct, setSelectedProduct,
        selectedAdressCityOrigin, setSelectedAdressCityOrigin,
        selectedStateOrigin, setSelectedStateOrigin,
        originStateId, setOriginStateId,
        selectedAdressCityReturn, setSelectedAdressCityReturn,
        selectedStateReturn, setSelectedStateReturn,
        returnStateId, setReturnStateId
    }
}
export default useGlobalStates;